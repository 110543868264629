import React, { PureComponent } from "react";
import SvgBulletsLong from "../../img/graphics/bullets-long";
import SvgChart from "../../img/graphics/chart-graphic";
import BGGraphic9 from "../../img/graphics/BGGraphic9";

class StudioSection3 extends PureComponent {
  render() {
    const smallText = "BENEFITS";
    const header = "Why Are Nonprofits Choosing Starfish Studio?";
    const para1 =
      "At Starfish, we take the legitimacy of our partner organizations seriously. Every non-profit on our platform:";
    return (
      <div className="bg-blue-50 relative min-h-[100vh] md:min-h-[80vh] items-center justify-center flex flex-col w-screen">
        <div className="flex flex-col pt-10 md:pt-0 md:flex-row gap-y-5 justify-center items-center gap-x-20 max-w-[350px] sm:max-w-xl md:max-w-3xl xl:max-w-5xl">
          <div className="flex flex-col gap-y-4">
            <span className="text-gray-500 text-sm md:text-lg font-bold">
              {smallText}
            </span>
            <span className="text-start text-4xl xl:text-5xl text-starfish-black-900 font-bold max-w-fit">
              {header}
            </span>
            {/* <span className="text-start text-2xl max-w-[350px] sm:max-w-3xl xl:max-w-5xl text-starfish-black-600">
              {para1}
            </span> */}
            <div className="flex flex-row gap-x-4">
              <SvgBulletsLong strokeWidth={2} className="min-w-[12px] pt-3" />
              <ul className="text-start text-md md:text-xl max-w-[350px] flex flex-col gap-y-3 sm:gap-y-9 md:gap-y-0 xl:gap-y-7 lead-none sm:max-w-3xl xl:max-w-5xl">
                <li className="text-starfish-black-900 font-semibold">
                  Data-Driven Results:
                  <span className="text-starfish-black-600 font-normal">
                    {" "}
                    Advanced reporting and analytics to understand your supporters and optimize campaigns.
                  </span>
                </li>
                <li className="text-starfish-black-900 font-semibold">
                  Targeted Strategies:
                  <span className="text-starfish-black-600 font-normal">
                    {" "}
                    Custom-tailored approaches based on your non-profit's unique
                    goals and audience.
                  </span>
                </li>
                <li className="text-starfish-black-900 font-semibold">
                  Cost-Effective:
                  <span className="text-starfish-black-600 font-normal">
                    {" "}
                    Transparent pricing and performance-based optimization to
                    maximize your ROI.
                  </span>
                </li>
                <li className="text-starfish-black-900 font-semibold">
                  Privacy First:
                  <span className="text-starfish-black-600 font-normal">
                    {" "}
                    All your data is private, assembled only for your organization and kept confidential.
                  </span>
                </li>
                <li className="text-starfish-black-900 font-semibold">
                  Experience and Expertise:
                  <span className="text-starfish-black-600 font-normal">
                    {" "}
                    Starfish Studio is an expert in promoting Nonprofits on social media.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex">
            <SvgChart classname="max-w-48 md:max-w-64"/>
          </div>
        </div>
        <BGGraphic9
            className="absolute bottom-0 left-3 md:left-10 max-w-[70px] md:max-w-[150px] transform translate-y-1/2 z-50"/>
      </div>
    );
  }
}

export default StudioSection3;
