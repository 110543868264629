import React, { PureComponent } from "react";

class StudioSection1 extends PureComponent {
  render() {
    const header = "Start sharing your Nonprofit's message to a bigger audience!";
    const para1 =
      "Starfish Studio was created specifically to help Nonprofits powerfully use multiple social media platforms to grow their supporters and grow their donations through data-driven strategies and proven results!";
    return (
      <div className="flex flex-col justify-center items-center gap-y-5 min-h-[80vh] pt-80 max-w-[350px] sm:max-w-xl md:max-w-3xl xl:max-w-5xl self-center">
        <span className="text-center text-2xl md:text-4xl xl:text-5xl text-starfish-black-900 font-bold">
          {header}
        </span>
        <span className="text-center text-xl xl:text-2xl text-starfish-black-600">
          {para1}
        </span>
        <a
          style={{ textDecoration: "none" }}
          href="/authentication?activeTab=signup"
          target={"_blank"}
          className="row-ac primary-button body1-bold"
        >
          Get Started Today
        </a>
      </div>
    );
  }
}

export default StudioSection1;
