import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva } from "class-variance-authority"
import Spinner from "../Spinner";

const buttonVariants = cva(
    `inline-flex items-center justify-center text-md font-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50`,
    {
        variants: {
            variant: {
                brand: "bg-brandCoral hover:bg-brandCoral/90 text-white",
                blue: "bg-indigo-500 hover:bg-indigo-500/90 text-white",
                blueLight: "bg-indigo-500/10 hover:bg-indigo-500/20 text-indigo-600 dark:text-indigo-100",
                default: "bg-blue-500 hover:bg-blue-600 text-white",
                lemon: "bg-lemon hover:bg-lemon/80 text-slate-700",
                white: 'bg-white hover:bg-white/80 text-blue-500',
                destructive:
                    "bg-red-400 hover:bg-red-500 text-white",
                outline:
                    "border border-input bg-background text-slate-500 hover:bg-accent hover:text-accent-foreground dark:text-darkAccent-light",
                secondary:
                    "bg-neutral-100 text-neutral-900 hover:bg-neutral-200",
                secondaryOverlay: "bg-neutral-100/20 text-white hover:bg-neutral-200/40",
                studioOverlayLight: "bg-white/20 text-white hover:bg-white/40 border border-white/40",
                ghost: "hover:bg-neutral-100 text-neutral-500 hover:text-neutral-800 dark:hover:text-neutral-300 backdrop-blur-xl",
                ghostOverlay: "text-white hover:bg-neutral-200/40",
                link: "text-blue-500 font-lighter",
                sky: "bg-sky-400 hover:bg-sky-500/90 text-white",
                green: 'bg-emerald-500 text-white'

            },
            size: {
                default: "h-12 px-5 py-2 pb-2.5 text-[18px]",
                sm: "h-8 px-3",
                lg: "h-12 px-5 text-lg",
                icon: "h-10 w-10",
            },
            radius: {
                default: 'rounded-full',
                sm: 'rounded-sm',
                lg: 'rounded-lg',
                full: 'rounded-full',
            },
        },
        defaultVariants: {
            variant: "default",
            size: "default",
            radius: "default",
        },
    }
)


const Button = React.forwardRef(
    ({ className, variant, radius, size, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "button"
        if (props.loading) {
            return (
                <Comp
                    className={buttonVariants({ variant, size, radius, className }) + ' rounded-full'}
                    ref={ref}
                    {...props}
                >
                    <Spinner light />
                </Comp>
            )
        }
        return (
            <Comp
                className={buttonVariants({ variant, size, radius, className }) + ' rounded-full'}
                ref={ref}
                {...props}
            >
                <div className='flex flex-row items-center gap-1'>
                    {props.children}
                    {props.icon}
                </div>
            </Comp>
        )
    }
)
Button.displayName = "Button"

export { Button, buttonVariants }
