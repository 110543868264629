import SvgSponsored from "../icons/SVGSponsored";
import React from "react";
import ProfilePicture from "./ProfilePicture";

function SponsoredPostBanner({ post }) {
  return post.boosted ? (
    <div className="items-center flex bg-blue-500/10 p-2 px-4 text-white rounded-full flex-row gap-2 primary mb-16 justify-between">
      <div className="gap-2 flex flex-row">
        <SvgSponsored className="text-blue-500 scale-[.8]" size={24} />
        <p className="text-blue-500 font-semibold">Boosted Post</p>
      </div>
      <a
        className="text-sm text-gray-500 flex flex-row gap-x-2 items-center"
        href={`/profile/${post.boosted}`}
      >
        <p>boosted by</p>
        <ProfilePicture userID={post.boosted} size={28} />
      </a>
    </div>
  ) : post.challenge_name ? (
    <div className="flex bg-blue-500/10 p-2 px-4 text-white rounded-full flex-row items-end gap-2 primary mb-16">
      <SvgSponsored className="text-blue-500 scale-[.8]" size={24} />
      <p className="text-blue-500 font-semibold">Sponsored Challenge</p>
    </div>
  ) : (
    <div className="flex bg-blue-500/10 p-2 px-4 text-white rounded-full flex-row items-end gap-2 primary mb-16">
      <SvgSponsored className="text-blue-500 scale-[.8]" size={24} />
      <p className="text-blue-500 font-semibold">Sponsored Post</p>
    </div>
  );
}

export default SponsoredPostBanner;
