import Marquee from "react-fast-marquee";
import React, { useEffect, useState } from "react";
import NPOPicture from "./atoms/NPOPicture";
import { getActivity } from "../api/activity";
import ProfilePicture from "./atoms/ProfilePicture";
import {Button} from "./atoms/Button";
import SvgZap from "./icons/SvgZap";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}


function ActivityBanner({authUser, history}) {
    const [activity, setActivity] = useState([])

    useEffect(() => {
        getAllActivity();
    }, [])

    const getAllActivity = async () => {
        const res = await getActivity(authUser);
        setActivity(res.activity);
    }

    return (
        <div className='-mb-10'>
            <div className='flex flex-row items-center gap-1 mb-2'>
                <SvgZap/>
                <p className='text-xl font-semibold '>
                    Activity Feed
                </p>
            </div>
            <Marquee direction={"left"} speed={20} gradient gradientWidth={20} className=' pb-10 pt-1 overflow-y-hidden'>
                <div className='flex flex-row items-center'>
                    {activity.map(item => {
                        if (item.type === 'donated') {
                            const np = item.non_profit
                            return (
                                <div
                                    className={`bg-white shadow-xl shadow-slate-300/40 overflow-hidden border border-slate-200 rounded-xl mx-1 flex flex-col`}
                                    key={item.ein}
                                    onClick={() => this.navigateToNPO(item.name)}>
                                    <div className='flex flex-row items-center p-3'>
                                        <ProfilePicture
                                            initial={item.name[0]}
                                            userID={item.username}
                                            size={24}
                                        />
                                        <div className='flex flex-col flex-1 ml-1'>
                                            <p className='text-sm text-slate-800 font-semibold'>
                                                {item.name}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='bg-blue-500/5 p-3'>
                                        <p className='text-sm text-slate-700 mb-1'>
                                            Donated to:
                                        </p>
                                        <div className='flex flex-row items-center'>
                                            <NPOPicture
                                                size={32}
                                                np={np}
                                                logoUrl={np.ein}
                                                className={'border bg-white border-slate-200'}
                                            />
                                            <div className='flex flex-col flex-1 ml-1' style={{textAlign: "left"}}>
                                                <p className='text-sm text-slate-800 font-semibold'>
                                                    {np.name}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if (item.type === 'joined') {
                            return (
                                <div
                                    className={`bg-white shadow-xl shadow-slate-300/40  overflow-hidden border border-slate-200 rounded-xl mx-1 flex flex-col`}>
                                    <div className='flex flex-row items-center p-3'>
                                        <ProfilePicture
                                            initial={item.name[0]}
                                            userID={item.username}
                                            size={24}
                                        />
                                        <div className='flex flex-col flex-1 ml-1'>
                                            <p className='text-sm text-slate-800 font-semibold'>
                                                {item.name}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='bg-blue-500/5 p-3 flex flex-row gap-5 items-center'>
                                        <p className='text-sm'>
                                            Just joined Starfish,<br/>
                                            say hi!
                                        </p>
                                        <Button size={'sm'} className='' onClick={() => {history.push({pathname: `/profile/${item.username}`})}}>
                                            View profile
                                        </Button>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
            </Marquee>
        </div>
    )
}

export default withRouter(connect(mapStateToProps)(ActivityBanner));
