import React, {useState} from 'react';
import moment from 'moment';
import ProfilePicture from "../atoms/ProfilePicture";
import SvgPin from "../icons/SvgPin";
import SvgCalendar from "../icons/SvgCalendar";
import SvgCheck from '../icons/SvgCheck';
import {shareProfile} from "../../utils/share";
import SvgShare from '../icons/SvgShare';
import {Button} from "../atoms/Button";
import {CARD_STYLE, CARD_STYLE_NO_HOVER} from "../../utils/dummy";
import SvgEditNote from "../icons/SvgEditNote";
import {wait} from "../../utils/timestamp";
import EditProfileHeaderModal from '../modals/EditProfileHeaderModal';
import SvgMessage from '../icons/SvgMessage';
import StreakIndicator from "../atoms/StreakIndicator";
import SvgHot from "../icons/SvgHot";
import SvgChatBubbles from "../icons/SvgChatBubbles";

const ProfileHeaderCard = ({
                               user,
                               sameUser,
                               followees,
                               addFollowee,
                               removeFollowee,
                               navigateToList,
                               mobile,
                               navigateToDms
                           }) => {
    const followerCount = user.followers ? Object.keys(user.followers).length : 0;
    const followeeCount = user.followees ? Object.keys(user.followees).length : 0;
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [copiedLink, setCopiedLink] = useState(false);

    const handleFollowClick = () => {
        if (followees.includes(user.username)) {
            removeFollowee(user.username);
        } else {
            addFollowee(user.username);
        }
    };

    const toggleEditModal = () => {
        setEditModalOpen(!editModalOpen)
    }

    const copyLinkToClipboard = async (e) => {
        e.stopPropagation()
        setCopiedLink(true)
        const link = await shareProfile(user.username, user.first_name);
        console.log("FF", link)
        // `https://share.starfishapp.com/profile/${user.username}`
        navigator.clipboard.writeText(link)
        wait(3000).then(() => {
            setCopiedLink(false)
        })
    }

    const firstName = user.first_name || " ";
    const lastName = user.last_name || " ";
    const interests = user.interests || [];
    return (
        <div className={`${CARD_STYLE_NO_HOVER} rounded-xl border-2 border-slate-100 p-5`}>
            <div className='relative'>
                <img
                    src={`https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/users%2Fcover_photo%2F${user.username}.jpeg?alt=media`}
                    onError={(e) => {
                        e.target.src = require('../../img/blank_cover.jpg');
                    }}
                    style={{height: mobile ? '100px' : '160px', objectFit: 'cover'}}
                    className="rounded-xl w-full"
                />
            </div>
            <div className="ml-3 flex flex-row justify-between">
                <div className='-mt-12 '>
                    <ProfilePicture
                        initial={firstName[0] + lastName[0]}
                        userID={user.username}
                        size={120}
                    />
                </div>
                <div className='flex flex-row items-center gap-2'>
                    <StreakIndicator
                        name={firstName}
                        sameUser={sameUser}
                        streak={user?.current_streak}
                        longestStreak={user?.longest_streak}
                    >
                        <div className='inline-flex p-3 px-4 rounded-full gap-1 bg-red-400 hover:bg-[#de858a]'>
                            <SvgHot className={'w-5 h-5 text-white'}/>
                            <p className='font-semibold text-sm text-white ml-1'>
                                {user.current_streak} day streak
                            </p>
                        </div>
                    </StreakIndicator>
                    <div className="flex items-center gap-3">
                        {sameUser ?
                            <Button variant={'secondary'} onClick={copyLinkToClipboard}
                                    className={`flex flex-row items-center gap-1 cursor-pointer ${copiedLink && 'text-blue-500'}`}>
                                {copiedLink ?
                                    <SvgCheck className={'w-4 h-4 scale-[1.2]'}/>
                                    :
                                    <SvgShare className={'w-4 h-4 scale-[1.2]'}/>
                                }
                                <p className='text-sm'>
                                    {copiedLink ?
                                        'Link copied!' :
                                        'Share profile'
                                    }
                                </p>
                            </Button>
                            :
                            <Button variant={'secondary'} onClick={navigateToDms}
                                    className={`flex text-sm flex-row items-center gap-1 cursor-pointer`}>
                                    <SvgChatBubbles className={'w-4 h-4 scale-[1.2]'}/>
                                    Message
                            </Button>
                        }
                    </div>
                    {sameUser ?
                        <div className="flex items-center gap-3">
                            <Button className='text-sm' variant={'secondary'} size={'icon'} onClick={toggleEditModal}>
                                <SvgEditNote/>
                            </Button>
                        </div>
                        :
                        <div className="flex items-center gap-3">
                            <Button
                                onClick={handleFollowClick}
                                variant={followees.includes(user.username) ? 'secondary' : 'default'}
                                radius="full"
                                className="text-sm py-2 px-4"
                            >
                                {followees.includes(user.username) ? 'Unfollow' : 'Follow'}
                            </Button>
                        </div>
                    }
                </div>
            </div>
            <div className="flex justify-between -mt-1">
                <div className='flex flex-col gap-0'>
                    <h1 className="text-3xl text-slate-800 font-semibold">
                        {user.first_name} {user.last_name}
                    </h1>
                    <p className="text-lg font-semibold text-slate-600 mb-4">
                        {user.tagline}
                    </p>
                </div>
            </div>
            <div className="flex gap-4 flex-wrap">
                <div className="flex items-center">
                    <SvgPin className="text-blue-500" style={{width: 16, height: 16}}/>
                    <p className="text-md font-semibold text-blue-500 ml-1">
                        {user.city}, {user.state}
                    </p>
                </div>
                <div className="flex items-center">
                    <SvgCalendar className="text-blue-500" style={{width: 16, height: 16}}/>
                    <p className="text-md font-semibold text-blue-500 ml-1">
                        Joined {moment(user.created_at).format("MMM 'YY")}
                    </p>
                </div>
            </div>
            <div className="flex gap-4 mt-2">
                <div onClick={() => navigateToList("following", user.username)} className="cursor-pointer">
                    <p className="text-lg font-medium text-slate-700">
                        <span>{followeeCount + interests.length}</span> Following
                    </p>
                </div>
                <div onClick={() => navigateToList("followers", user.username)} className="cursor-pointer">
                    <p className="text-lg font-medium text-slate-700">
                        <span>{followerCount}</span> Followers
                    </p>
                </div>
            </div>
            <EditProfileHeaderModal
                isOpen={editModalOpen}
                close={toggleEditModal}
            />
        </div>
    );
};

export default ProfileHeaderCard;
