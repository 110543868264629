import React, { useEffect, useRef, useState } from "react";
import LoadingSection from "../components/LoadingSection";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import { getFollowerPosts, getForYouPosts } from "../api/feeds";
import PostComponent from "../components/posts/PostComponent";
import { connect } from "react-redux";
import { Button } from "../components/atoms/Button";
import SvgUserPlus from "../components/icons/SvgUserPlus";
import SectionTabs from "../components/SectionTabs";
import SvgAddCircle from "../components/icons/SvgAddCircle";
import ChallengeFeedPage from "./ChallengeFeedPage";
import { Link, withRouter } from "react-router-dom";
import SvgEditNote from "../components/icons/SvgEditNote";
import SvgTarget from "../components/icons/SvgTarget";
import EventInFeedWidget from "../components/suggestions/EventInFeedWidget";
import { getActivity } from "../api/activity";
import PostChallengeComponent from "../components/posts/PostChallengeComponent";
import SearchPage from "./SearchPage";

function mapDispatchToProps(dispatch) {
  return {
    toggleCreatePostModal: () => {
      dispatch({
        type: "TOGGLE_CREATE_POST_MODAL",
      });
    },
    updatePosts: (posts) => {
      dispatch({
        type: "UPDATE_POSTS",
        posts,
      });
    },
    updateScrollPosition: (scrollPosition) => {
      dispatch({
        type: "UPDATE_SCROLL_POSITION",
        scrollPosition,
      });
    },
  };
}

function mapStateToProps(state) {
  return {
    authUser: state.userReducer.authUser,
    posts: state.postReducer.posts,
    path: state.userReducer.path,
    scrollPosition: state.userReducer.scrollPosition,
  };
}

const FeedPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(null);
  const [posts, setPosts] = useState([]);
  const [activeTab, setActiveTab] = useState("For you");
  const [fetchingPosts, setFetchingPosts] = useState(false);
  const [activity, setActivity] = useState([]);
  const [viewedArray, setViewedArray] = useState([]);
  const addToViewedArray = (challengeId) => {
    setViewedArray((prevArray) => [...prevArray, challengeId]);
  };
  const list = useRef(null);

  const onScroll = () => {
    if (list.current) {
      const { scrollTop, scrollHeight, clientHeight } = list.current;
      props.updateScrollPosition(scrollTop);

      if (scrollTop + clientHeight >= scrollHeight - 4000 && !fetchingPosts) {
        getPosts(activeTab, index, posts);
      }
    }
  };

  const scrollToTop = () => {
    if (list.current) {
      list.current.scrollTo({
        top: 0,
        behavior: "smooth", // Optional: for smooth scrolling
      });
    }
  };

  useEffect(() => {
    if (
      !!props.path &&
      props.path !== null &&
      props.path !== "null" &&
      !props.path.includes("referral")
    ) {
      props.history.push({ pathname: `/` + props.path });
      return;
    }
    const queryParams = new URLSearchParams(window.location.search);
    const activeTab = queryParams.get("activeTab") || "For you";
    changeTab(activeTab);

    getAllActivity();
  }, [activeTab]);

  const getAllActivity = async () => {
    const res = await getActivity(props.authUser);
    setActivity(res.activity);
  };

  const getPosts = async (tab, index, posts) => {
    setFetchingPosts(true);
    let res = {};
    if (tab === "For you") {
      res = await getForYouPosts(props.authUser, index, viewedArray);
    } else if (tab === "Following") {
      res = await getFollowerPosts(props.authUser, index, viewedArray);
    }

    // Catch case to make sure all posts in the feed are unique
    // const uniquePosts = res.posts.filter(newPost =>
    //     !Object.values(props.posts).some(existingPost => existingPost.post_ID === newPost.post_ID)
    // );
    props.updatePosts([...Object.values(props.posts), ...res.posts]);
    setPosts([...posts, ...res.posts]);
    setIndex(res.index);
    setLoading(false);
    setFetchingPosts(false);
  };

  const changeTab = (tab) => {
    setLoading(true);
    setActiveTab(tab);
    scrollToTop();
    getPosts(tab, null, []);

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("activeTab", tab);

    const params = `activeTab=${tab}`;
    const newUrl = `${window.location.pathname}?${params}`;
    props.history.replace(newUrl);
  };

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <MiddleViewHeader
        title={"My Feed"}
        RightComponent={
          <div className="flex flex-row items-center gap-3">
            <Link to={"/create-challenge"}>
              <Button size={"lg"} icon={SvgAddCircle}>
                <SvgTarget className={"w-5 h-5"} />
                Start a Challenge
              </Button>
            </Link>
            <Button
              size={"lg"}
              icon={SvgUserPlus}
              onClick={props.toggleCreatePostModal}
            >
              <SvgEditNote />
              Post
            </Button>
          </div>
        }
      >
        <div
          className={`-mb-4`}
        >
          <SectionTabs
            activeTab={activeTab}
            changeTab={changeTab}
            tabs={["For you", "Following", "Challenges"]}
          />
        </div>
      </MiddleViewHeader>
      {loading ? (
        <LoadingSection />
      ) : activeTab === "Challenges" ? (
        <>
          <ChallengeFeedPage />
        </>
      ) : posts.length < 1 ? (
        <div className="flex flex-col items-center mt-10 gap-y-5">
          <p className="text-starfish-black-800 font-semibold text-xl">
            No Posts in the Last 30 Days.
          </p>
          <Link
            to="/network"
            className="bg-blue-500 hover:bg-blue-600 text-white h-12 px-5 py-2 pb-2.5 text-[18px] items-center rounded-full"
          >
            <p className="mt-[2px] font-medium">Follow non-profits to see their posts!</p>
          </Link>
        </div>
      ) : (
        <div
          className="flex flex-col flex-1 overflow-y-scroll"
          onScroll={() => onScroll()}
          ref={list}
        >
          {posts.map((post, i) => {
            const activityIndex = Math.floor(i / 10) - 1;
            if (i > 0 && i % 10 === 0 && activityIndex < activity.length) {
              return <EventInFeedWidget activity={activity[activityIndex]} />;
            }
            return posts[i].challenge_name ? (
              <PostChallengeComponent
                challenge={props.posts[post.post_ID]}
                addToViewedArray={addToViewedArray}
              />
            ) : (
              <PostComponent
                post={props.posts[post.post_ID]}
                addToViewedArray={addToViewedArray}
              />
            );
          })}
          <div>
            <LoadingSection />
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeedPage)
);
