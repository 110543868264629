import * as React from "react";

const SvgBullets = (props) => (
  <svg
    width="12"
    height="137"
    viewBox="0 0 12 137"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <line
      x1="6"
      y1="131.007"
      x2="6"
      y2="0.999969"
      stroke="#3B82F6"
      stroke-width="2"
    />
    <circle cx="6" cy="6" r="5.5" fill="#3B82F6" stroke="#3B82F6" />
    <circle cx="6" cy="131" r="5.5" fill="#3B82F6" stroke="#3B82F6" />
    <circle cx="6" cy="69" r="5.5" fill="#3B82F6" stroke="#3B82F6" />
  </svg>
);

export default SvgBullets;
