import * as React from "react";

const SvgBulletsLong = (props) => (
    <svg
        width="12"
        height="400"
        viewBox="0 0 12 400"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
    >
      <line
          x1="6"
          y1="350"
          x2="6"
          y2="0.999969"
          stroke="#3B82F6"
          stroke-width="2"
      />
      <circle cx="6" cy="6" r="5.5" fill="#3B82F6" stroke="#3B82F6" />
      <circle cx="6" cy="88" r="5.5" fill="#3B82F6" stroke="#3B82F6" />
      <circle cx="6" cy="175" r="5.5" fill="#3B82F6" stroke="#3B82F6" />
      <circle cx="6" cy="260" r="5.5" fill="#3B82F6" stroke="#3B82F6" />
      <circle cx="6" cy="350" r="5.5" fill="#3B82F6" stroke="#3B82F6" />
    </svg>
);

export default SvgBulletsLong;
