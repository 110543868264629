import React, {Component} from "react";
import {getDonations} from "../api/donations";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import {CARD_STYLE, CARD_STYLE_NO_HOVER, DOCUMENTS} from "../utils/dummy";
import {connect} from "react-redux";
import {disableAccount, logout} from "../api/authentication";
import SvgLogout from "../components/icons/SvgLogout";
import SvgTrash from "../components/icons/SvgTrash";
import LoadingSection from "../components/LoadingSection";
import SvgArrowForward from "../components/icons/ArrowForward";
import SponsoredSwitch from "../components/posts/SponsoredSwitch";
import { getUserSubscribed,updateUserSubscribed} from "../api/users";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
    }
}

class Settings extends Component {
    state = {
        loading: false,
        isSubscribed: true
    }

    async componentDidMount() {
        const res = await getUserSubscribed(this.props.authUser);
        if (res === true) {
            this.setState({isSubscribed: true});
        } else { this.setState({isSubscribed: false}); }
    }
    
    deleteAccount = () => {
        disableAccount(this.props.authUser);
        logout();
    }

    handleSwitchChange = async () => {
        this.setState({loading: true});
        const res = await updateUserSubscribed(this.props.authUser);
        this.setState({loading: false, isSubscribed: res});
    }

    render() {
        return (
          <div className="flex flex-col flex-1 overflow-hidden">
            <MiddleViewHeader title={"Settings"}></MiddleViewHeader>
            {this.state.loading ? (
              <LoadingSection />
            ) : (
              <div className="flex flex-col flex-1 p-5">
                <div className="flex flex-col gap-1">
                  <div className={`${CARD_STYLE_NO_HOVER} flex flex-col gap-5`}>
                    <div className="flex flex-col gap-1 text-slate-500">
                      <p className="text-slate-500 text-sm">Your account</p>
                      <p className="text-lg font-semibold text-slate-800">
                        {this.props.user.email}
                      </p>
                    </div>
                    <div className="flex flex-col gap-1 text-slate-500">
                      <p className="text-slate-500 text-sm">Contact us</p>
                      <a
                        href="mailto:support@starfishapp.com"
                        target={"_blank"}
                        rel={"noreferrer"}
                        className="text-lg flex flex-row items-center gap-2 font-semibold text-slate-800 hover:text-blue-500"
                      >
                        support@starfishapp.com
                        <SvgArrowForward
                          className={"w-4 h-4 rotate-[-45deg]"}
                        />
                      </a>
                    </div>
                    <div className="flex flex-col gap-1 text-slate-500">
                      <p className="text-slate-500 text-sm">
                        Subscribe to emails?
                      </p>
                      <div className="flex flex-row gap-x-2">
                          <SponsoredSwitch
                            checked={this.state.isSubscribed}
                            onChange={this.handleSwitchChange}
                          />
                        {this.state.isSubscribed ? <p>Yes</p> : <p>No</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  {DOCUMENTS.map((setting) => {
                    return (
                      <a
                        className={`${CARD_STYLE} flex flex-row items-center gap-3`}
                        href={setting.url}
                        target={"_blank"}
                      >
                        {setting.icon}
                        <p className="text-lg text-slate-800">
                          {setting.title}
                        </p>
                      </a>
                    );
                  })}
                  <div
                    className={`${CARD_STYLE} flex flex-row items-center gap-3 text-red-400 hover:text-red-500`}
                    onClick={() => logout()}
                  >
                    <SvgLogout width={20} height={20} />
                    <p className="text-lg">Logout</p>
                  </div>
                  <div
                    className={`${CARD_STYLE} flex flex-row items-center gap-3 text-red-400 hover:text-red-500`}
                    onClick={() => this.deleteAccount()}
                  >
                    <SvgTrash width={20} height={20} />
                    <p className="text-lg">Delete Account</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
    }

}


export default connect(mapStateToProps)(Settings);
