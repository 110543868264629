import React, { Component } from "react";
import AnimatedInput from "../components/AnimatedInput";
import ModalComponent from "../components/ModalComponent";
import Spinner from "../components/Spinner";
import SvgAlert from "./icons/SvgAlert";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { validateEmail } from "../utils/strings";

function mapStateToProps(state) {
  return {
    authUser: state.userReducer.authUser,
  };
}

class DonateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      selected: "",
      firstName: "",
      lastName: "",
      email: "",
    };
  }

  basePreselects = [1, 5, 10, 25];
  mostPopularIndex = 1;

  onChangeFirstName = (firstName) => {
    this.setState({ firstName, error: "" });
    this.props.updateFirst(firstName);
  };

  onChangeLastName = (lastName) => {
    this.setState({ lastName, error: "" });
    this.props.updateLast(lastName);
  };

  onChangeEmail = (email) => {
    this.setState({ email: email.toLowerCase(), error: "" });
    this.props.updateEmail(email);
  };

  onChangeAmount = (event) => {
    const numericValue = event.target.value.replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
    this.props.updateAmount(numericValue);
    this.setState({ amount: numericValue });
  };

  donatePressed = () => {
    let error;
    if (!this.props.authUser?.uid) {
      if (!validateEmail(this.state.email)) {
        error = "Please enter a valid email address.";
      }
      if (error) {
        this.setState({ error });
        return;
      }
    }
    this.setState({ processing: true });
    this.props.openAuthModal();
  };

  render() {
    const buttonEnabled = !this.props.authUser?.uid
      ? !!this.state.amount &&
        !!this.state.firstName &&
        !!this.state.lastName &&
        !!this.state.email &&
        validateEmail(this.state.email)
      : !!this.state.amount;
    const nonProfit = this.props.nonProfit || {};
    const challenge = this.props.challenge;
    const handleClick = (index) => {
      this.setState({
        amount: this.basePreselects[index].toString(),
        selected: this.basePreselects[index],
      });
      const numericValue = this.basePreselects[index]
        .toString()
        .replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
      this.props.updateAmount(numericValue);
      this.setState({ amount: numericValue });
    };
    return (
      <ModalComponent
        isOpen={this.props.isOpen}
        size={this.props.mobile ? "full" : "lg"}
        toggle={this.props.closeModal}
        headerToggle
        FooterComponent={
          <div className="flex flex-col items-center">
            <button
              disabled={!buttonEnabled}
              onClick={this.donatePressed}
              className="primary-button body1-bold"
              style={{ width: 300, opacity: !buttonEnabled && 0.3 }}
            >
              {this.state.processing ? <Spinner /> : "Confirm Donation"}
            </button>
            <p className="body4 black-50 mv-12">
              By continuing, you agree with Starfish{" "}
              <a
                href={
                  "https://app.termly.io/document/terms-of-use-for-website/eaf25433-f52f-4673-ae16-e16370111fea"
                }
                target={"_blank"}
                rel={"noreferrer"}
                className="body4-bold primary"
              >
                terms
              </a>{" "}
              and{" "}
              <a
                href={
                  "https://app.termly.io/document/privacy-policy/3cc64057-0435-44b3-b613-35eea60b52a7"
                }
                target={"_blank"}
                rel={"noreferrer"}
                className="body4-bold primary"
              >
                privacy policy
              </a>
              . This donation will be made via Our Change Foundation 501(c)(3)
              Change Foundation 501(c)(3)
              (EIN: 86-3353394). No goods
              or services were provided in
              exchange for this donation.
            </p>
          </div>
        }
      >
        <div
          className={`col-ac-jc ${this.props.mobile ? "p-5" : "p-5"} pt-0`}
          style={{ flex: 1 }}
        >
          <div className="col-ac text-center">
            <h1
              className={`${
                this.props.mobile ? "title1" : "title1"
              } black-80 mb-12`}
            >
              Enter your donation for {nonProfit.name}
            </h1>
            {!!challenge && (
              <p className="body2 black-50">
                You are about to participate in {challenge.challenge_name}!
              </p>
            )}
            <div
              className="mt-40 row-ac body1"
              style={{ marginTop: this.props.mobile && 24 }}
            >
              <div className="row-plain">
                <p className="black-50 body1-bold mt-8">$</p>
                <input
                  name="amount"
                  onChange={this.onChangeAmount}
                  value={this.state.amount}
                  //type="number"
                  className="donate-input"
                  placeholder="0"
                  size={this.state.amount.length + 1}
                  style={{
                    width: "auto",
                    maxWidth: "100%",
                    padding: 0, // Remove any padding
                    border: "none", // Remove border,
                  }}
                />
                <p className="black-50 body1-bold mt-8">USD</p>
              </div>
            </div>
            <div className="gap-x-4 flex flex-row">
              {this.basePreselects.map((key, index) => (
                <div className="flex flex-col gap-y-2">
                  <button onClick={() => handleClick(index)}>
                    <div
                      className={`flex flex-col items-center w-[75px] md:w-[150px] py-3 md:py-5 rounded-full ${
                        this.state.selected === this.basePreselects[index]
                          ? `bg-blue-500 text-white`
                          : `text-starfish-black-900 bg-white hover:scale-105 transition duration-150 outline outline-1 outline-blue-500`
                      }`}
                    >
                      <p className="text-lg md:text-2xl font-bold">
                        ${this.basePreselects[index]}
                      </p>
                    </div>
                  </button>
                  {index === this.mostPopularIndex && (
                    <div className="flex flex-col items-center font-bold text-blue-500">
                      <span className="md:hidden text-center">Popular!</span>
                      <span className="hidden md:flex text-center">
                        Most Popular!
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {this.state.amount === "0" && (
              <div
                className="body3 row-ac br-32 p-3"
                style={{ backgroundColor: "#cf364a10", color: "#cf364a" }}
              >
                <SvgAlert
                  style={{ width: 16, height: 16 }}
                  className={"mr-8"}
                />
                Please enter a donation amount
              </div>
            )}
            <div
              className="br-8 p-4 mt-8 col-plain w-100p pt-28"
              style={{ border: "1px solid #00000020" }}
            >
              <AnimatedInput
                name="memo"
                onChange={this.props.onChangeMemo}
                value={this.props.memo}
                type="text"
                className={"w-100p flex-1"}
                placeholder="Add a memo (optional)"
                multiline
                height={100}
                style={{ width: "100%", display: "flex" }}
              />
            </div>

            {!this.props.authUser?.uid && (
              <div className="w-full mt-6 flex flex-col pl-1 md:pl-0 py-2">
                <p className="text-blue-500 pb-5">
                  Please provide information for the donation receipt.
                </p>
                <div className="flex flex-col md:flex-row gap-x-3 gap-y-7">
                  <div className="flex">
                    <AnimatedInput
                      name="email"
                      onChange={this.onChangeEmail}
                      value={this.state.email}
                      type="email"
                      placeholder="Your Email"
                    />
                  </div>
                  <div className="flex">
                    <AnimatedInput
                      name="firstName"
                      onChange={this.onChangeFirstName}
                      value={this.state.firstName}
                      type="text"
                      className={"w-100p"}
                      placeholder="First Name"
                    />
                  </div>
                  <div className="flex">
                    <AnimatedInput
                      name="lastName"
                      onChange={this.onChangeLastName}
                      value={this.state.lastName}
                      type="text"
                      className={"w-100p"}
                      placeholder="Last Name"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ModalComponent>
    );
  }
}

export default withRouter(connect(mapStateToProps)(DonateModal));
