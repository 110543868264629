import React, {Component} from "react";
import logo from '../../img/brand/LogoNew (1).png'
import {Link, withRouter} from "react-router-dom";
import SvgBell from "../icons/SvgBell";
import SvgHome from "../icons/SvgHome";
import {connect} from "react-redux";
import SvgTarget from "../icons/SvgTarget";
import SvgCommunity from "../icons/SvgCommunity";
import SvgSearch from "../icons/SvgSearch";
import SvgMoney from "../icons/SvgMoney";
import DonateByInvitingModal from "../modals/DonateByInvitingModal";
import InviteLandingModal from "../modals/InviteLandingModal";
import SvgChatBubbles from "../icons/SvgChatBubbles";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        mobile: state.userReducer.mobile,
        authUser: state.userReducer.authUser,
        referralInfo: state.userReducer.referralInfo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setReferralInfo: (referralInfo) => {
            dispatch({
                type: "SET_REFERRAL_INFO",
                referralInfo,
            })
        },
    }
}

const NAV_ITEMS = [
    {
        icon: SvgHome,
        label: 'Home Feed',
        route: '/'
    },
    // {
    //     icon: SvgTarget,
    //     label: 'Campaign',
    //     route: '/campaign',
    //     badge: 1,
    // },
    {
        icon: SvgSearch,
        label: 'Search',
        route: '/network'
    },
    {
        icon: SvgCommunity,
        label: 'Groups',
        route: '/groups',
        // badge: 2,
    },
    {
        icon: SvgChatBubbles,
        label: 'Messages',
        route: '/messages',
        // badge: 2,
    },
    {
        icon: SvgBell,
        label: 'Notifications',
        route: '/notifications'
    },
]

class AppLeftSidebar extends Component {
    state = {
        activeTab: null,
        isInviteModalOpen: false,
        isInviteLandingModalOpen: true
    }

    componentDidMount() {
        this.updateStateFromRoute(this.props.location.pathname)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.updateStateFromRoute(this.props.location.pathname);
        }
    }

    updateStateFromRoute = (pathname) => {
        if (pathname?.includes('/network')) {
            this.setState({activeTab: 'Search'})
        } else if (pathname?.includes(this.props.user?.username)) {
            this.setState({activeTab: 'My Profile'})
        } else if (pathname?.includes('/challenges')) {
            this.setState({activeTab: 'Challenges'})
        } else if (pathname?.includes('/notifications')) {
            this.setState({activeTab: 'Notifications'})
        } else if (pathname?.includes('/history')) {
            this.setState({activeTab: 'My Giving History'})
        } else if (pathname?.includes('/settings')) {
            this.setState({activeTab: 'Settings'})
        } else if (pathname?.includes('/messages')) {
            this.setState({activeTab: 'Messages'})
        } else if (pathname?.includes('/groups')) {
            this.setState({activeTab: 'Groups'})
        }  else if (pathname?.includes('/campaign')) {
            this.setState({activeTab: 'Campaign'})
        } else if (pathname === "/") {
            this.setState({activeTab: 'Home Feed'})
        }
    }

    openInviteModal = () => {
        this.setState({isInviteModalOpen: true})
    }

    closeInviteModal = () => {
        this.setState({isInviteModalOpen: false})
    }

    closeInviteLandingModal = () => {
        this.props.setReferralInfo({success: false})
    }

    navigateToNPO = () => {
        this.props.history.push({pathname: `/non-profit/${this.props.referralInfo.np_id}`, state: {previous: "Back"}});
        this.closeInviteLandingModal();
    }

    render() {
        return (
            <div className='min-w-[20vw] p-5 lg:p-10 lg:pr-7 lg:pt-7 items-end flex flex-col gap-5'>
                <div className={''}>
                    <Link to={'/'} className='row-ac'>
                        <div className='relative'>
                            <img src={logo} alt={'Starfish'}
                                 className='w-[128px] mb-1 mr-48 brightness-50'/>
                        </div>
                    </Link>
                </div>
                <ul className='gap-3 flex flex-col flex-1'>
                    {NAV_ITEMS.map(item =>
                        <div key={item.label}>
                            <Link
                                to={item.route}
                                // onClick={() => this.setState({activeTab: item.label})}
                                className={`
                                    flex flex-row gap-3 items-center p-3 rounded-xl
                                    ${this.state.activeTab === item.label ?
                                    'bg-slate-100 cursor-pointer text-blue-500'
                                    :
                                    'hover:bg-slate-200 text-slate-500 hover:text-slate-800 cursor-pointer'
                                }
                                    `}>
                                <div className='relative'>
                                    <item.icon className={'w-6 h-6'}/>
                                    {item.badge &&
                                        <div
                                            className={`
                                                    bg-red-400 text-white text-xs font-medium rounded-full px-1 absolute bottom-[-6px] right-[-4px]
                                                    `}>
                                            {item.badge}
                                        </div>
                                    }
                                </div>
                                <p className='text-xl font-medium'>
                                    {item.label}
                                </p>
                            </Link>
                        </div>
                    )}
                </ul>
                <div className='flex flex-col items-end gap-3 text-lg text-slate-500'>
                    <div
                        onClick={this.openInviteModal}
                        className='bg-blue-100 cursor-pointer hover:bg-blue-200 gap-2 text-blue-500  rounded-xl px-3 py-1 -mr-3'>
                        <div className='flex flex-row items-center gap-2'>
                            <SvgMoney className={'w-5 h-5'}/>
                            <p className='font-medium'>
                                Donate By Inviting
                            </p>
                        </div>
                        <p className='max-w-[186px] text-sm'>
                            Starfish donates up to <span className='font-semibold'>$5,000 each week</span> to support
                            your
                            favorite causes
                        </p>
                    </div>
                    <Link to={'/profile/' + this.props.user?.username}
                          className={this.state.activeTab === 'My Profile' ? 'text-blue-500' : 'hover:text-slate-800 cursor-pointer'}>
                        <p>
                            My Profile
                        </p>
                    </Link>
                    <Link to={'/history'}
                          className={this.state.activeTab === 'My Giving History' ? 'text-blue-500' : 'hover:text-slate-800 cursor-pointer'}>
                        <p>
                            My Giving History
                        </p>
                    </Link>
                    <a href={'https://v9ga2rfswmh.typeform.com/to/xkIz0IJi'} target={'_blank'} rel={'noreferrer'}
                       className={'hover:text-slate-800 cursor-pointer'}>
                        <p>
                            Feedback Survey
                        </p>
                    </a>
                    <Link to={'/settings'}
                          className={this.state.activeTab === 'Settings' ? 'text-blue-500' : 'hover:text-slate-800 cursor-pointer'}>
                        <p>
                            Settings
                        </p>
                    </Link>
                </div>
                <DonateByInvitingModal
                    isOpen={this.state.isInviteModalOpen}
                    close={this.closeInviteModal}
                    authUser={this.props.authUser}
                />
                <InviteLandingModal
                    isOpen={this.props.referralInfo?.success}
                    close={this.closeInviteLandingModal}
                    navigateToNPO={this.navigateToNPO}
                    referralInfo={this.props.referralInfo}
                />
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppLeftSidebar));
