import React, { PureComponent } from "react";
import SvgShell from "../../img/graphics/shell";

class StudioSection2 extends PureComponent {
  render() {
    const header = "Two Powerful Solutions";
    const para1 =
      "Starfish Studio makes it easy to drive the results you want, whether you need to grow your audience or encourage donations.";
    return (
      <div className="relative min-h-[100vh] md:min-h-[80vh] items-center justify-center flex flex-row w-screen pb-32 bg-brand z-40 pt-64">
        <div className="flex flex-col pt-10 md:pt-0 gap-y-5 justify-center gap-x-20 max-w-[350px] sm:max-w-xl md:max-w-3xl">
          <span className="text-3xl md:text-5xl text-starfish-black-900 font-bold">
            {header}
          </span>
          <span className="text-xl xl:text-2xl text-starfish-black-600">
            {para1}
          </span>
          <div className="relative flex flex-col md:flex-row justify-between gap-y-5 gap-x-5 z-10">
            <div className="drop-shadow-2xl rounded-2xl h-96 w-[345px] px-10 py-7 relative z-20 bg-white">
              <div className="text-xl leading-tight flex flex-col pt-3">
                <span className="text-blue-500 font-bold pb-2 text-2xl">
                  Follower Growth <br />
                </span>
                <span className="text-starfish-black-600">
                  Build a dedicated community of supporters with our
                  comprehensive follower growth strategy.
                </span>
                <span className="text-starfish-black-800 pt-2 font-semibold">
                  Key Features
                </span>
                <ul className="list-disc list-inside pt-2 text-lg">
                  <li className="text-starfish-black-600">
                    Dynamic creative campaigns
                  </li>
                  <li className="text-starfish-black-600">
                    Advanced audience insights
                  </li>
                  <li className="text-starfish-black-600">
                    Engagement optimization
                  </li>
                </ul>
              </div>
              <SvgShell className="z-80 w-40 h-40 absolute -bottom-28 -left-20 -rotate-45 " />
            </div>
            <div className="drop-shadow-2xl rounded-2xl h-96 w-[350px] px-10 py-7 relative z-20 bg-white">
              <div className="text-xl leading-tight flex flex-col pt-3">
                <span className="text-blue-500 font-bold pb-2 text-2xl">
                  Donation Drive <br />
                </span>
                <span className="text-starfish-black-600">
                  Convert followers into donors with our multi-channel donation
                  generation strategy.
                </span>
                <span className="text-starfish-black-800 pt-2 font-semibold">
                  Key Features
                </span>
                <ul className="list-disc list-inside pt-2 text-lg">
                  <li className="text-starfish-black-600">
                    Multi-platform campaign management
                  </li>
                  <li className="text-starfish-black-600">
                    Email nurture sequences
                  </li>
                  <li className="text-starfish-black-600">
                    Custom donation challenges
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StudioSection2;
