import React, { Component } from "react";
import { connect } from "react-redux";
import SvgConfetti from "../components/icons/SvgConfetti";
import Confetti from "react-confetti";
import { Link } from "react-router-dom";

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    mobile: state.userReducer.mobile,
  };
}

class DonationSuccessPage extends Component {
  state = {
    nonProfitName: "",
    link: "",
    authed: "False",
  };

  componentDidMount = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const ein = queryParameters.get("ein", null);
    const challengeId = queryParameters.get("challenge_id", null);
    const nonProfitName = queryParameters.get("non_profit_name", null);
    const authed = queryParameters.get("authed", null);

    let link = "/non-profit/" + ein;
    if (!!challengeId) {
      link = "/challenge/" + challengeId;
    }
    this.setState({ nonProfitName, link, authed });
  };

  render() {
    return (
      <div className="flex flex-col md:flex-row items-center justify-center gap-x-10 mt-[150px] px-4">
        <Confetti
          number={this.props.mobile ? 120 : 200}
          colors={["#edf7f5", "#054a91", "#81a4cd", "#f17300", "#e3d265"]}
          initialVelocityY={5}
          recycle={false}
        />
        <div className="flex flex-col items-center max-w-fit">
          <SvgConfetti width={160} height={160} />
          <p className="text-[30px] md:text-[42px] font-extrabold my-2 md:my-8">
            Thanks for pitching in!
          </p>
          <p className="title1 black-70 max-w-2xl text-center mb-8 md:mb-16">
            Your donation to {this.state.nonProfitName} is confirmed.
          </p>
          <p className="body1 black-70 line-clamp-3 max-w-3xl text-center">
            You will be notified with a confirmation and any status updates via
            email. Thank you for making Starfish the place to illuminate the
            good! Download our app to track your donation history and find new
            causes!
          </p>
          <div className="flex flex-row gap-x-5 mb-2">
            <Link style={{ textDecoration: "none" }} to={this.state.link}>
              <button className="primary-button body1-bold mt-32">
                Head Back
              </button>
            </Link>
            {this.state.authed === "False" && (
              <Link
                style={{ textDecoration: "none" }}
                href="https://www.starfishapp.com/authentication?activeTab=signup"
              >
                <button className="primary-button body1-bold mt-32">
                  Or, Create An Account
                </button>
              </Link>
            )}
          </div>
        </div>
        {this.state.authed === "False" && (
          <div className="w-[1px] bg-zinc-400 h-96 hidden md:flex"></div>
        )}
        {this.state.authed === "False" && (
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center">
              <div className="text-md text-black mv-12 flex flex-col gap-y-1 md:gap-y-3 leading-tight">
                <p className="text-blue-500 font-bold text-2xl md:text-[36px]">
                  Why Create an Account?
                </p>
                <p className="text-black font-bold text-xl">
                  Create a Starfish account and we'll donate another $1!
                </p>
                <ul className="gap-y-2 list-disc pl-5 text-sm md:text-lg">
                  <li>Connect with your favorite non-profits.</li>
                  <li>Find other individuals making an impact.</li>
                  <li>Share the good with the Starfish Network</li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(DonationSuccessPage);
