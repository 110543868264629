import * as React from "react";

const SvgShell = (props) => (
  <svg
    height="193.997"
    viewBox="0 0 207.926 193.997"
    width="207.926"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      d="m168.595 20.575c-37.428-28.914-120.88-23.23-142.775-8.072-28.707 19.874-27.664 34.484-23.874 73.137 3.789 38.653 37.516 70.863 37.516 70.863s-12.037 21.452-9.61 21.979c21.231 4.611 54.326 19.705 57.358 14.4s6.821-21.979 21.979-25.01c15.158-3.032 87.845-19.706 95.116-44.716 9.474-32.589 1.308-73.986-35.709-102.581zm-124.992 102.744c-.623.187-1.252.275-1.87.275-2.796 0-5.379-1.818-6.224-4.634-4.669-15.565-11.821-83.934-12.123-86.837-.372-3.57 2.221-6.766 5.792-7.138 3.574-.373 6.766 2.222 7.138 5.792 2.023 19.436 8.06 72.495 11.646 84.447 1.031 3.438-.92 7.062-4.358 8.094zm38.295.275c-1.04 0-2.096-.25-3.076-.778-3.161-1.702-4.343-5.644-2.642-8.805 4.159-7.723 10.715-66.646 9.111-81.88-.376-3.57 2.214-6.769 5.784-7.145 3.572-.38 6.769 2.213 7.145 5.784 1.433 13.606-4.026 77.207-10.594 89.404-1.174 2.181-3.415 3.42-5.729 3.42zm24.446-.914c-1.042.619-2.187.914-3.316.914-2.217 0-4.378-1.135-5.593-3.179-1.835-3.085-.821-7.074 2.265-8.909 7.963-4.735 35.272-46.911 44.247-60.771 2.939-4.54 5.063-7.82 6.148-9.37 2.059-2.941 6.111-3.656 9.053-1.598 2.941 2.059 3.656 6.112 1.598 9.053-.951 1.358-3.129 4.722-5.886 8.98-22.771 35.167-39.735 59.659-48.514 64.879zm80.689-7.758c-5.923 1.975-55.25 28.855-63.639 36.422-1.243 1.122-2.8 1.674-4.352 1.674-1.776 0-3.545-.724-4.828-2.146-2.405-2.666-2.193-6.776.472-9.18 9.884-8.917 60.841-36.638 68.235-39.103 3.405-1.134 7.086.705 8.222 4.111 1.135 3.406-.705 7.087-4.111 8.222z"
      fill="#3B82F6"
    />
  </svg>
);

export default SvgShell;
