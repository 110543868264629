import React, {Component} from "react";
import AuthModal from "../components/AuthModal";
import {withRouter} from "react-router-dom";
import {createDonationCheckout} from "../api/stripe_connect";
import {connect} from "react-redux";
import {getUser} from "../api/users";
import {getUserChallenges} from "../api/challenges";
import SectionTabs from "../components/SectionTabs";
import LoadingSection from "../components/LoadingSection";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import {CARD_STYLE} from "../utils/dummy";
import {getUserPosts} from "../api/feeds";
import PostComponent from "../components/posts/PostComponent";
import ZeroStateArea from "../components/atoms/ZeroStateArea";
import ProfileAboutSection from "../components/profile/ProfileAboutSection";
import ProfileDonationsSection from "../components/profile/ProfileDonationsSection";
import ProfileHeaderCard from "../components/profile/ProfileHeaderCard";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
        mobile: state.userReducer.mobile,
        followees: state.userReducer.followees,
        posts: state.postReducer.posts,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
        addFollowee: (followee) => {
            dispatch({
                type: "ADD_FOLLOWEE",
                followee,
            })
        },
        removeFollowee: (followee) => {
            dispatch({
                type: "REMOVE_FOLLOWEE",
                followee,
            })
        },
        addInterest: (interest) => {
            dispatch({
                type: "ADD_INTEREST",
                interest,
            })
        },
        removeInterest: (interest) => {
            dispatch({
                type: "REMOVE_INTEREST",
                interest,
            })
        },
        updatePosts: (posts) => {
            dispatch({
                type: "UPDATE_POSTS",
                posts,
            })
        },
    }
}


class ProfilePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileMenu: false,
            mobile: false,
            user: {},
            authModalOpen: false,
            loading: true,
            sameUser: false,
            challenges: [],
            posts: [],
            activeTab: 'About'
        }
    }

    getUserInfo = async () => {
        // TODO (cory) open streak modal and then set new_streak to false in redux

        this.setState({loading: true});
        const user = this.props.user;

        if (!user) {
            this.setState({loading: false});
            return;
        }

        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const user_id = lastArg.split("?")[0];

        let targetUsername;
        if (user_id === user.slug || user_id === user.username) {
            this.setState({sameUser: true});
            targetUsername = user.username;
        } else {
            const res = await getUser(this.props.authUser, user_id);
            this.setState({user: res.user, sameUser: false})
            targetUsername = res?.user?.username;
        }
        const result = await getUserChallenges(this.props.authUser, targetUsername);
        const posts = await getUserPosts(this.props.authUser, targetUsername);

        this.props.updatePosts(posts.posts);
        this.setState({challenges: result.challenges, posts: posts.posts, loading: false});
    }

    componentDidMount = () => {
        this.getUserInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.user !== prevProps.user || this.props.match.params !== prevProps.match.params) {
            this.getUserInfo();
        }
    }

    createCheckout = async () => {
        console.log("CREATING DONATION CHECKOUT")
        createDonationCheckout(this.props.authUser, this.state.amount, this.state.nonProfit.ein, false, null, this.state.memo);
    }

    openAuthModal = () => {
        this.setState({authModalOpen: true});
    }

    closeAuthModal = () => {
        this.setState({authModalOpen: false})
    }

    navigateToNPO = (ein) => {
        this.props.history.push({pathname: `/non-profit/${ein}`, state: {previous: "Back"}})
    }

    navigateToList = (title, userId) => {
        this.props.history.push({pathname: `/${title}/${userId}`, state: {previous: "Back"}})
    }

    navigateToDms = () => {
        this.props.history.push({pathname: `/messages`, state: {previous: "Back", userId: this.state.user.username}});
    }

    changeTab = (tab) => {
        this.setState({activeTab: tab});
    };

    render() {
        const user = this.state.sameUser ? this.props.user : this.state.user || {};
        const connections = user.connections || [];
        const TABS = ['About', 'Donations', 'Posts']
        let previous = "";
        if (!!this.props.location.state) {
            previous = this.props.location.state.previous;
        }
        if (!this.props.user) {
            return (
                <div className='col-ac-jc' style={{width: '100vw', height: '100vh'}}>
                    <p className='black-50 mb-32'>
                        Please log in to view this user.
                    </p>
                    <button onClick={() => this.setState({authModalOpen: true})}
                            className={`body1-bold primary-button  ${this.props.mobile && 'button-sm'} `}>
                        Log In
                    </button>
                    <AuthModal
                        isOpen={this.state.authModalOpen}
                        closeModal={this.closeAuthModal}
                        mobile={this.props.mobile}
                        createCheckout={this.createCheckout}
                    />
                </div>
            )
        }
        if (this.state.loading) {
            return (
                <LoadingSection/>
            )
        }
        return (
            <div className='flex flex-col flex-1 overflow-hidden'>
                <MiddleViewHeader
                    title={this.state.sameUser ? 'My Profile' : `${user.first_name}'s Profile`}
                    back={!this.state.sameUser}
                />
                <div className='p-5 flex flex-col flex-1 overflow-y-scroll'>
                    <ProfileHeaderCard
                        user={user}
                        sameUser={this.state.sameUser}
                        followees={this.props.followees}
                        addFollowee={this.props.addFollowee}
                        removeFollowee={this.props.removeFollowee}
                        navigateToList={this.navigateToList}
                        mobile={this.props.mobile}
                        navigateToDms={this.navigateToDms}
                    />

                    <div className='mb-3'>
                        <SectionTabs
                            activeTab={this.state.activeTab}
                            changeTab={this.changeTab}
                            tabs={TABS}
                            labelOverrides={[this.state.sameUser ? 'About Me' : `About ${user.first_name}`, null, null]}
                        />
                    </div>
                    {this.state.activeTab === 'About' &&
                        <ProfileAboutSection
                            connections={connections}
                            user={user}
                            sameUser={this.state.sameUser}
                        />
                    }
                    {this.state.activeTab === 'Donations' &&
                        <ProfileDonationsSection
                            user={user}
                            challenges={this.state.challenges}
                        />
                    }
                    {this.state.activeTab === 'Posts' &&
                        <div className='rounded-xl flex flex-col gap-2'>
                            {this.state.posts.map((post, i) => {
                                    return (
                                        <PostComponent
                                            post={this.props.posts[post.post_ID]}
                                            className={`${CARD_STYLE} mb-0`}
                                        />
                                    )
                                }
                            )}
                            {this.state.posts.length === 0 ?
                                <ZeroStateArea
                                    title={'Nothing here...'}
                                    sub={`${user.first_name} has not made any posts yet.`}
                                />
                                : null
                            }
                        </div>
                    }
                </div>
                <AuthModal
                    isOpen={this.state.authModalOpen}
                    closeModal={this.closeAuthModal}
                    mobile={this.props.mobile}
                    createCheckout={this.createCheckout}
                />
            </div>
        )
    }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfilePage));
