import React, {Component} from "react";
import moment from "moment/moment";
import ProfilePicture from "../atoms/ProfilePicture";
import NPOPicture from "../atoms/NPOPicture";
import {connect} from "react-redux";
import SvgCheck from "../icons/SvgCheck";
import PostAttachments from "./PostAttachments";
import PostContent from "./PostContent";
import {Link, withRouter} from "react-router-dom";
import PostInteractionsRow from "./PostInteractionsRow";
import {Button} from "../atoms/Button";
import SuggestedPostBanner from "../atoms/SuggestedPostBanner";
import SponsoredPostBanner from "../atoms/SponsoredPostBanner";
import { postViewed, sponsoredClicked } from "../../api/posts";

import ViewMoreBanner from "../atoms/ViewMore";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setImageViewerModalOpen: (images, index) => {
            dispatch({
                type: "SET_IMAGE_VIEWER_MODAL_OPEN",
                images,
                index
            })
        },
        toggleFullPostModal: (fullPostModalID) => {
            dispatch({
                type: "TOGGLE_FULL_POST_MODAL",
                fullPostModalID,
            })
        },
    }
}


class PostComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldShowSeeMore: false,
            hasBeenViewed: false,
        };
        this.contentRef = React.createRef();
    }


    handleAddView = () => {
      this.props.addToViewedArray(this.props.post.post_ID);
    };


    componentDidMount() {
        this.checkContentHeight();
        this.observer = new IntersectionObserver(
            (entries) => {
              const entry = entries[0];
              if (entry.isIntersecting && !this.state.hasBeenViewed) {
                this.handleAddView();
                postViewed(this.props.authUser, this.props.post.post_ID);
                this.setState({ hasBeenViewed: true });
                this.observer.unobserve(this.contentRef.current);
              }
            },
            { threshold: 0.5 }
          );

          if (this.contentRef.current) {
            this.observer.observe(this.contentRef.current);
          }
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    checkContentHeight = () => {
        const contentWrapper = this.contentRef.current.querySelector('.relative.w-full.overflow-hidden');
      if (contentWrapper && contentWrapper.scrollHeight > 188) {
          this.setState({shouldShowSeeMore: true});
      }
  };


    openImageLightbox = (imageURLs, index, creator, creator_name) => {
        this.props.setImageViewerModalOpen(imageURLs, index);
    }

    navigateToPost = (e) => {
        e.stopPropagation()
        this.props.history.push('/post/' + this.props.post.post_ID);
    }

    postClicked = () => {
        sponsoredClicked(this.props.authUser, this.props.post.post_ID);
    }

    render() {
        const user = this.props.user || {};
        const postId = this.props.post.post_ID;
        const post = this.props.post || {};
        const np = post.non_profit || {};
        return (
          <div
            onClick={() => this.props.toggleFullPostModal(postId)}
            ref={this.contentRef}
          >
            <div
              className={`bg-white flex-1 mx-auto ${
                !this.props.noPadding && "p-5"
              } flex flex-col ${
                !this.props.noBorder && "border-b border-slate-100"
              } cursor-pointer ${this.props.className}`}
              style={{ maxWidth: this.props.isRepost ? 600 : 700 }}
            >
              {post.sponsored ? (
                <SponsoredPostBanner post={post} />
              ) : (
                <SuggestedPostBanner post={post} />
              )}
              <div className="flex flex-row gap-1">
                <div>
                  {post.np_post ? (
                    <NPOPicture
                      np={np}
                      size={50}
                      logoUrl={np.logo_url}
                      initial={np.name[0]}
                      className={"bg-white border border-slate-200"}
                    />
                  ) : (
                    <ProfilePicture
                      size={50}
                      initial={post.creator_name[0]}
                      userID={post.creator}
                      className={"bg-white border border-slate-200"}
                    />
                  )}
                </div>
                <div className="flex flex-col flex-1 gap-4 mt-[-6px]">
                  <div>
                    <div>
                      <div className="flex flex-row items-end gap-2 justify-between">
                        <div className="flex flex-row items-end gap-2">
                          <p className="text-lg font-semibold text-slate-800 line-clamp-1">
                            {post.creator_name}
                          </p>
                          {this.props.challengeParticipant && (
                            <SvgCheck className={"primary"} />
                          )}
                          {post.scraped_ig ? (
                            <p className="text-base text-slate-500 mb-0.5 line-clamp-1">
                              - {moment(post.created_at).fromNow("")} via IG
                            </p>
                          ) : (
                            <p className="text-base text-slate-500 mb-0.5 line-clamp-1">
                              - {moment(post.created_at).fromNow("")}
                            </p>
                          )}
                        </div>
                        {post.sponsored && post.non_profits !== "" && (
                          <div className="min-w-fit px-3 py-2 bg-starfish-yellow text-starfish-black-900 text-[11px] rounded-full">
                            Verified 501(C)(3)
                          </div>
                        )}
                      </div>
                      {post.scope !== "public" && !this.props.isInsideGroup && (
                        <div className={"flex flex-row items-center -mt-1"}>
                          <p className=" text-slate-500">Posted In:</p>
                          <div
                            onClick={() =>
                              this.props.toggleFullPostModal(postId)
                            }
                          >
                            <Link to={"/groups"}>
                              <p className="text-blue-500 font-semibold cursor-pointer hover:text-blue-600">
                                &nbsp;{post.group.name}
                              </p>
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      ref={this.contentRef}
                      className={`relative w-full overflow-hidden ${
                        this.state.shouldShowSeeMore && "max-h-[188px]"
                      }`}
                    >
                      <PostContent post={post} />
                      {this.state.shouldShowSeeMore && (
                        <div
                          className="absolute bottom-0 left-0 right-0 h-12"
                          style={{
                            background:
                              "linear-gradient(to top, white, #FFFFFF00",
                          }}
                        />
                      )}
                      {this.state.shouldShowSeeMore && (
                        <Button
                          variant={"default"}
                          size={"sm"}
                          className="absolute bottom-2 right-2"
                        >
                          See More
                        </Button>
                      )}
                    </div>
                  </div>
                  <PostAttachments post={post} inFeed hideViewMore={this.props.hideViewMore} />
                  <div className="flex flex-col justify-between">
                      {!this.props.hideViewMore && (
                          <>
                              {!(post.image_urls.length > 0 || post.videos?.length > 0) && (
                                  <div className="flex flex-col justify-between">
                                      {post.sponsored &&
                                          (post.for_profit_url || post.non_profit_url) && (
                                              <>
                                                  <ViewMoreBanner
                                                      post={post}
                                                      onClick={this.postClicked}
                                                  />
                                              </>
                                          )}
                                      {post.sponsored &&
                                          post.non_profit &&
                                          !post.for_profit_url && (
                                              <ViewMoreBanner
                                                  post={post}
                                                  onClick={this.postClicked}
                                              />
                                          )}
                                  </div>
                              )}
                          </>
                      )}
                    {!this.props.hidePostInteractions && (
                      <PostInteractionsRow user={user} post={post} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostComponent));
