import React, {useState, useEffect} from "react";
import {CARD_STYLE, sample_users} from "../utils/dummy";
import { getSuggestions } from "../api/users";
import ProfilePicture from "./atoms/ProfilePicture";
import SvgPin from "./icons/SvgPin";
import SvgUserSearch from "./icons/SvgUserSearch";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom"


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    }
}


function UserSuggestions(props) {
    const [userSuggestions, setUserSuggestions] = useState([]);

    useEffect(() => {
        suggestions()
    }, [])

    const suggestions = async () => {
        const suggestions = await getSuggestions(props.authUser);
        setUserSuggestions(suggestions.users)

    }
    const navigateToUser = (slug) => {
        props.history.push(`/profile/${slug}`)
    }
    return (
        <div>
            <div className='flex flex-row items-center gap-1 mb-2'>
                <SvgUserSearch/>
                <p className='text-xl font-semibold '>
                    Suggested Users
                </p>
            </div>
            <div>
                {userSuggestions.slice(0, 4).map(user => {
                    return (
                        <div
                            className={`${CARD_STYLE} p-[12px] flex flex-row items-center`}
                            key={user.username}
                            onClick={() => navigateToUser(user.slug)}>
                            <ProfilePicture
                                initial={user.first_name[0] + user.last_name[0]}
                                userID={user.username}
                                size={44}
                            />
                            <div className='flex flex-col flex-1 ml-4' style={{textAlign: "left"}}>
                                <p className='text-base text-slate-800 font-semibold'>
                                    {user.first_name} {user.last_name}
                                </p>
                                {!!user.tagline ?
                                    <p className='text-slate-500 text-sm -mt-1.5 line-clamp-1'>
                                        {user.tagline}
                                    </p>
                                    : null}
                                <div className='row-ac'>
                                    <SvgPin className={'text-blue-500 w-3 h-3'}/>
                                    <p className='text-sm text-blue-500 ml-4'>
                                        {user.city}, {user.state}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default withRouter(connect(mapStateToProps)(UserSuggestions));

