import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getNonProfitPublic } from "../api/nonProfits";
import DownloadAppCTA from "./Main/DownloadAppCTA";
import SvgPin from "../components/icons/SvgPin";
import SvgMoney from "../components/icons/SvgMoney";
import SvgLink from "../components/icons/SvgLink";
import { connect } from "react-redux";
import LoadingSection from "../components/LoadingSection";
import { CARD_STYLE_NO_HOVER } from "../utils/dummy";
import { Button } from "../components/atoms/Button";
import SvgArrowForward from "../components/icons/ArrowForward";
import NPOPicture from "../components/atoms/NPOPicture";
import DonateModal from "../components/DonateModal";
import AccountModal from "../components/modals/CheckAccountModal";
import { createDonationCheckoutUnauth } from "../api/stripe_connect";

function mapStateToProps(state) {
  return {
    mobile: state.userReducer.mobile,
  };
}

class PublicNonProfitPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenu: false,
      mobile: false,
      nonProfit: {},
      loading: true,
      amount: 0,
      memo: "",
      accountModalOpen: false,
      firstName: "",
      lastName: "",
      email: "",
    };
  }

  getNpInfo = async () => {
    this.setState({ loading: true });
    const parts = window.location.pathname.split("/");
    const lastArg = parts[2];
    const ein = lastArg.split("?")[0];

    const res = await getNonProfitPublic(ein);
    this.setState({
      loading: false,
      nonProfit: res.non_profit,
    });
  };

  componentDidMount = () => {
    this.getNpInfo();
  };

  toAuth = () => {
    this.props.history.push(
      `/authentication?activeTab=signup&path=non-profit/${this.state.nonProfit.ein}`
    );
  };

  openDonateModal = () => {
    this.setState({ donateModalOpen: true });
  };

  closeDonateModal = () => {
    this.setState({ donateModalOpen: false });
  };

  openAccountModal = () => {
    this.setState({ accountModalOpen: true });
  };

  closeAccountModal = () => {
    this.setState({ accountModalOpen: false });
  };

  updateFirst = (first) => {
    this.setState({ firstName: first });
  };

  openAuthModal = () => {
    this.createCheckout();
  };

  updateLast = (last) => {
    this.setState({ lastName: last });
  };

  updateEmail = (email) => {
    this.setState({ email: email });
  };

  updateAmount = (amount) => {
    this.setState({ amount: amount });
  };

  onChangeMemo = (memo) => {
    this.setState({ memo });
  };

  createCheckout = async () => {
    console.log("CREATING DONATION CHECKOUT");
    createDonationCheckoutUnauth(
      this.state.amount,
      this.state.nonProfit.ein,
      this.state.firstNamefalse,
      this.state.lastName,
      this.state.email,
      false,
      null,
      this.state.memo
    );
  };

  render() {
    const nonProfit = this.state.nonProfit || {};
    let url = nonProfit.website_url;
    if (!!url && !url.includes("http")) {
      url = "http://" + url;
    }
    return (
      <>
      <head>
        <title>{nonProfit.name}</title>
        <meta name="description" content={nonProfit.mission || ""} />
        <meta name="robots" content="index, follow"/>
      </head>
      <div
        className="col-plain page-wrapper col-ac"
        style={{ paddingTop: 120 }}
      >
        <div
          className="absolute cover-parent hero-wrapper"
          style={{ zIndex: -1, opacity: 0.3 }}
        />
        <div
          className="flex flex-col flex-1 overflow-hidden bg-white"
          style={{ maxWidth: this.props.mobile ? null : "60vw" }}
        >
          {this.state.loading ? (
            <LoadingSection />
          ) : (
            <div
              className="p-5 flex flex-col flex-1 overflow-y-scroll"
              style={{
                maxWidth: this.props.mobile ? "100vw" : "60vw",
                minWidth: this.props.mobile ? "100vw" : "60vw",
              }}
            >
              <div className={`${CARD_STYLE_NO_HOVER}`}>
                <img
                  src={nonProfit.cover_photo}
                  style={{
                    width: "100%",
                    height: this.props.mobile ? 100 : 160,
                    objectFit: "cover",
                    border: "1px solid #00000020",
                  }}
                  className="br-8"
                  onError={(e) => {
                    e.target.src = require("../img/blank_cover.jpg");
                    this.setState({ imageError: true });
                  }}
                />
                <div style={{ marginTop: -100, marginLeft: 12 }}>
                  <NPOPicture
                    size={120}
                    np={nonProfit}
                    logoUrl={nonProfit.logo_url}
                    className={"border-2 border-white mr-8"}
                  />
                </div>
                <div className="row-jb mt-12">
                  <div className="flex flex-col gap-1">
                    <h1 className="text-2xl text-slate-800 font-semibold">
                      {nonProfit.name}
                    </h1>
                    <p className="text-md font-medium text-slate-500">
                      {nonProfit.category}
                    </p>
                  </div>
                  <div className="hidden md:flex flex-row items-center gap-3">
                    <Button
                      onClick={this.toAuth}
                      variant={"default"}
                      radius={"full"}
                    >
                      Follow
                    </Button>

                    <Button
                      onClick={this.openAccountModal}
                      variant={"default"}
                      radius={"full"}
                    >
                      <SvgMoney className={"w-5 h-5"} /> Donate
                    </Button>
                  </div>
                </div>
                <div className="mt-1 flex flex-row items-center gap-3">
                  <div className="row-ac">
                    <SvgPin className={"text-blue-500 w-3 h-3"} />
                    <p className="text-md text-blue-500 ml-4">
                      {nonProfit.city}, {nonProfit.state}
                    </p>
                  </div>
                  <a
                    className="row-ac"
                    href={"https://nonprofit.starfishapp.com/claim"}
                    target={"_blank"}
                  >
                    <SvgArrowForward className={"text-blue-500 w-3 h-3"} />
                    <p className="text-md text-blue-500 ml-4">
                      Claim this profile
                    </p>
                  </a>
                </div>
                <div className="flex md:hidden flex-row items-center gap-3 pt-3">
                  <Button
                    onClick={this.toAuth}
                    variant={"default"}
                    radius={"full"}
                  >
                    Follow
                  </Button>

                  <Button
                    onClick={this.openAccountModal}
                    variant={"default"}
                    radius={"full"}
                  >
                    <SvgMoney className={"w-5 h-5"} /> Donate
                  </Button>
                </div>
                <p className="text-md text-slate-700 my-5">
                  {nonProfit.mission}
                </p>
                <div className="flex flex-row gap-5 wrap">
                  {!!nonProfit.num_employees ? (
                    <p className="text-md text-slate-700">
                      <span className="font-semibold">
                        {nonProfit.num_employees}
                      </span>{" "}
                      employees
                    </p>
                  ) : null}
                  {!!nonProfit.ruling_year ? (
                    <p className="text-md text-slate-700">
                      Since{" "}
                      <span className="font-semibold">
                        {nonProfit.ruling_year}
                      </span>
                    </p>
                  ) : null}
                  {!!nonProfit.ein ? (
                    <p className="text-md text-slate-700">
                      EIN:{" "}
                      <span className="font-semibold">{nonProfit.ein}</span>
                    </p>
                  ) : null}
                  {!!nonProfit.website_url ? (
                    <div
                      className="row-ac text-slate-700 hover:text-blue-500 cursor-pointer"
                      style={{ cursor: "pointer" }}
                    >
                      <SvgLink
                        style={{ width: 20, height: 20 }}
                        className={""}
                      />
                      <a
                        className="text-md font-semibold ml-2"
                        style={{ textDecoration: "none" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={url}
                      >
                        Website
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
              <DownloadAppCTA
                mobile={this.props.mobile}
                ctaText={"View full profile"}
              />
            </div>
          )}
        </div>
        <AccountModal
          isOpen={this.state.accountModalOpen}
          closeModal={this.closeAccountModal}
          mobile={this.props.mobile}
          donateOpen={() => {
            this.setState({ accountModalOpen: false });
            this.openDonateModal();
          }}
          url={`/authentication?path=non-profit/${nonProfit.ein}`}
        />
        <DonateModal
          isOpen={this.state.donateModalOpen}
          closeModal={this.closeDonateModal}
          openAuthModal={this.openAuthModal}
          mobile={this.props.mobile}
          nonProfit={nonProfit}
          updateAmount={this.updateAmount}
          memo={this.state.memo}
          onChangeMemo={this.onChangeMemo}
          updateFirst={this.updateFirst}
          updateLast={this.updateLast}
          updateEmail={this.updateEmail}
        />
      </div>
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps)(PublicNonProfitPage));
