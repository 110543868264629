import * as React from "react"

function SvgSponsored(props) {
    return (
<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M1.00303 11.9995C0.813867 12.0001 0.628403 11.9471 0.468186 11.8465C0.307969 11.7459 0.179576 11.6019 0.097923 11.4313C0.01627 11.2606 -0.015291 11.0703 0.0069071 10.8824C0.0291052 10.6945 0.104151 10.5168 0.223326 10.3699L10.1196 0.17256C10.1938 0.0868632 10.295 0.0289532 10.4064 0.00833498C10.5179 -0.0122832 10.6331 0.00561537 10.7331 0.059093C10.833 0.112571 10.9119 0.19845 10.9566 0.302634C11.0013 0.406818 11.0093 0.523116 10.9792 0.63244L9.05998 6.65087C9.00338 6.80236 8.98438 6.96531 9.00459 7.12576C9.0248 7.2862 9.08363 7.43935 9.17602 7.57206C9.26842 7.70477 9.39162 7.81308 9.53507 7.88771C9.67852 7.96234 9.83793 8.00105 9.99962 8.00052H16.997C17.1861 7.99988 17.3716 8.05292 17.5318 8.1535C17.692 8.25408 17.8204 8.39806 17.9021 8.56872C17.9837 8.73937 18.0153 8.92969 17.9931 9.11757C17.9709 9.30545 17.8958 9.48318 17.7767 9.6301L7.88042 19.8274C7.80619 19.9131 7.70503 19.971 7.59355 19.9917C7.48207 20.0123 7.36689 19.9944 7.26693 19.9409C7.16696 19.8874 7.08814 19.8016 7.04341 19.6974C6.99867 19.5932 6.99068 19.4769 7.02075 19.3676L8.94002 13.3491C8.99662 13.1976 9.01562 13.0347 8.99541 12.8742C8.9752 12.7138 8.91637 12.5607 8.82398 12.4279C8.73158 12.2952 8.60838 12.1869 8.46493 12.1123C8.32148 12.0377 8.16207 11.999 8.00038 11.9995H1.00303Z" fill="#054A91"/>
</svg>

    )
}

export default SvgSponsored
