import React, { Component } from "react";
import moment from "moment";
import ChallengeDonationBar from "../components/ChallengeDonationBar";
import ChallengeAwarenessBar from "../components/ChallengeAwarenessBar";
import DonationCard from "../components/DonationCard";
import AwarenessCard from "../components/AwarenessCard";
import SvgMoney from "../components/icons/SvgMoney";
import {
  createChallengeAction,
  getChallenge,
  getChallengeActions,
  getPrivateChallenge,
  getPrivateChallengeName,
  getChallengeByName,
} from "../api/challenges";
import { getNonProfitMentions } from "../api/feeds";
import AuthModal from "../components/AuthModal";
import DonateModal from "../components/DonateModal";
import { wait } from "../utils/timestamp";
import SvgCopy from "../components/icons/SvgCopy";
import SvgCheck from "../components/icons/SvgCheck";
import SvgTarget from "../components/icons/SvgTarget";
import { createDonationCheckout } from "../api/stripe_connect";
import SvgFire from "../components/icons/SvgFire";
import { withRouter } from "react-router-dom";
import SvgPiggy from "../components/icons/SvgPiggy";
import { formatStatistics } from "../utils/helpers";
import SvgMegaphone from "../components/icons/SvgMegaphone";
import ChallengeEndDate from "../components/ChallengeEndDate";
import SectionTabs from "../components/SectionTabs";
import PostComponent from "../components/posts/PostComponent";
import ZeroStateArea from "../components/atoms/ZeroStateArea";
import SvgArrowForward from "../components/icons/ArrowForward";
import { connect } from "react-redux";
import LoadingSection from "../components/LoadingSection";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import { CARD_STYLE_NO_HOVER } from "../utils/dummy";
import { Button } from "../components/atoms/Button";
import NPOPicture from "../components/atoms/NPOPicture";
import ChallengePledgeBar from "../components/ChallengePledgeBar";
import PledgeCard from "../components/PledgeCard";
import RenderHtml from "../components/atoms/RenderHtml";

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    authUser: state.userReducer.authUser,
    mobile: state.userReducer.mobile,
    interests: state.userReducer.interests,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addInterest: (interest) => {
      dispatch({
        type: "ADD_INTEREST",
        interest,
      });
    },
  };
}

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenu: false,
      featured_artists: [],
      featured_venues: [],
      mobile: false,
      challenge: {},
      nonProfit: {},
      actions: [],
      authModalOpen: false,
      donateModalOpen: false,
      copied: false,
      activeTab: "Challenge Activity",
      amount: 0,
      loading: true,
      posts: [],
      memo: "",
      authedUser: false,
    };
  }

  componentDidMount = () => {
    this.getInfo();
  };

 getInfo = async () => {
    const parts = window.location.pathname.split("/");
    const numArgs = parts.length - 2;
    let challenge, actions, challenge_name, np_name;

    if (numArgs === 1 && !!this.props.authUser?.uid) {
      challenge_name = parts[parts.length - 1].split("?")[0];
      challenge = await getPrivateChallenge(
        this.props.authUser,
        challenge_name
      );
    } else if (numArgs === 2) {
      challenge_name = parts[3].split("?")[0];
      np_name = parts[2].split("?")[0];
      challenge = await getPrivateChallengeName(
        this.props.authUser,
        challenge_name,
        np_name
      );
    } else {
      console.error("Invalid URL structure");
      return;
    }
    const challengeId = challenge.challenge.challenge_id;

    actions = await getChallengeActions(challengeId);
    const posts = await getNonProfitMentions(
      null,
      challenge.challenge.non_profit.ein
    );

    this.setState({
      challenge: challenge.challenge,
      nonProfit: challenge.challenge.non_profit,
      actions: actions.actions,
      loading: false,
      posts: posts.mentions,
      challengeId,
    });
  };

  pledgeChallenge = async () => {
    this.setState({ loading: true });
    const challenge = this.state.challenge;
    const params = {
      type: "pledge",
      amount: 1,
      non_profit_id: challenge.non_profit_id,
      challenge_id: challenge.challenge_id,
    };
    await createChallengeAction(this.props.authUser, params);
    this.getInfo();
  };

  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  followNp = async () => {
    this.setState({ loading: true });
    const { challenge, nonProfit } = this.state;
    const params = {
      type: "awareness",
      amount: 1,
      non_profit_id: challenge.non_profit_id,
      challenge_id: challenge.challenge_id,
    };
    await this.props.addInterest({ id: nonProfit.ein, name: nonProfit.name });
    await createChallengeAction(this.props.authUser, params);
    this.getInfo();
  };

  createCheckout = async () => {
    createDonationCheckout(
      this.props.authUser,
      this.state.amount,
      this.state.nonProfit.ein,
      false,
      this.state.challenge.challenge_id,
      this.state.memo
    );
  };

  openAuthModal = () => {
    if (!!this.props.user) {
      this.createCheckout();
    } else {
      this.setState({ authModalOpen: true });
    }
  };

  closeAuthModal = () => {
    this.setState({ authModalOpen: false });
  };

  openDonateModal = () => {
    if (!!this.props.user) {
      this.setState({ donateModalOpen: true });
    } else {
      this.setState({ authModalOpen: true });
    }
  };

  closeDonateModal = () => {
    this.setState({ donateModalOpen: false });
  };

  updateAmount = (amount) => {
    this.setState({ amount: amount });
  };

  onChangeMemo = (memo) => {
    this.setState({ memo });
  };

  copyLink = () => {
    navigator.clipboard.writeText(
      `https://share.starfishapp.com/challenge/${this.state.challengeId}`
    );
    this.setState({ copied: true });
    wait(2000).then(() => this.setState({ copied: false }));
  };

  navigateToNPO = () => {
    if (!this.props.user) {
      this.setState({ authModalOpen: true });
    } else {
      this.props.history.push({
        pathname: `/non-profit/${this.state.nonProfit.name}`,
        state: { previous: "Back" },
      });
    }
  };

  render() {
    const challenge = this.state.challenge || {};
    const expired = new Date() > new Date(challenge.end_date);
    const nonProfit = this.state.nonProfit || {};
    const matching = challenge.donation_match || 0;
    const myActions = this.state.actions.filter(
      (action) => action.user_id === this.props.user?.username
    );
    const challengeEndDate = moment(challenge.end_date);
    const currentDate = moment();
    const inPast = challengeEndDate.isBefore(currentDate);
    let previous = "";
    if (!!this.props.location.state) {
      previous = this.props.location.state.previous;
    }
    return (
      <div className="flex flex-col flex-1 overflow-hidden">
        <MiddleViewHeader back title={"Challenge"} previous={previous} />
        {this.state.loading ? (
          <LoadingSection />
        ) : (
          <div className="p-5 flex flex-col flex-1 overflow-y-scroll">
            <div className={`${CARD_STYLE_NO_HOVER}`}>
              <img
                src={challenge.cover_image}
                style={{
                  width: "100%",
                  height: this.props.mobile ? 120 : 240,
                  objectFit: "cover",
                }}
                className="br-8"
              />
              <div className="row-jb mt-8">
                <div>
                  <p className="text-xl font-bold">
                    {challenge.challenge_name}
                  </p>
                  <div className="row-ac body2 black-50 mb-8 mt-4">
                    <p>Created by</p>
                    <img
                      src={`https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/users%2Fprofile_picture%2F${challenge.creator}.jpeg?alt=media`}
                      style={{ width: 20, height: 20 }}
                      className="br-32 mx-1.5"
                    />
                    <p>{challenge.creator_name}</p>
                  </div>
                </div>
                <ChallengeEndDate challenge={challenge} />
              </div>
              <div className="flex flex-row gap-3 mt-1 mb-3">
                <Button
                  variant={"secondary"}
                  radius={"full"}
                  className="flex-1"
                  onClick={this.copyLink}
                >
                  {this.state.copied ? (
                    <SvgCheck
                      style={{ width: 18, height: 18 }}
                      className={"mr-4"}
                    />
                  ) : (
                    <SvgCopy
                      style={{ width: 18, height: 18 }}
                      className={"mr-4"}
                    />
                  )}
                  {this.state.copied ? "Link copied" : "Share"}
                </Button>
                {challenge.donations_goal > 0 && !expired ? (
                  <Button
                    onClick={this.openDonateModal}
                    variant={"default"}
                    radius={"full"}
                    className="flex-1"
                    style={{ backgroundColor: matching > 0 ? "#F8969C" : null }}
                  >
                    {matching > 0 ? (
                      <SvgFire
                        style={{ width: 20, height: 20, marginBottom: 1 }}
                        className={"mr-4"}
                      />
                    ) : (
                      <SvgMoney
                        style={{ width: 20, height: 20, marginBottom: 1 }}
                        className={"mr-4"}
                      />
                    )}
                    Donate {matching > 0 ? " (2x)" : null}
                  </Button>
                ) : null}
                {challenge.pledge_goal > 0 &&
                !expired &&
                !challenge.your_contributions?.direct_pledge ? (
                  <Button
                    onClick={this.pledgeChallenge}
                    variant={"default"}
                    radius={"full"}
                    className="flex-1"
                  >
                    <SvgTarget
                      style={{ width: 20, height: 20, marginBottom: 1 }}
                      className={"mr-4"}
                    />
                    I pledge
                  </Button>
                ) : null}
                {challenge.awareness_goal > 0 &&
                !expired &&
                !this.props.interests.some(
                  (interest) => interest.id === nonProfit.ein
                ) ? (
                  <Button
                    onClick={this.followNp}
                    variant={"default"}
                    radius={"full"}
                    className="flex-1"
                  >
                    <SvgTarget
                      style={{ width: 20, height: 20, marginBottom: 1 }}
                      className={"mr-4"}
                    />
                    Follow
                  </Button>
                ) : null}
                {/*<TooltipComponent*/}
                {/*    ToggleComponent={this.props.ToggleComponent}*/}
                {/*    toggleClassName={this.props.toggleClassName}*/}
                {/*    ContentComponent={*/}
                {/*        <div>*/}
                {/*            hi*/}
                {/*        </div>*/}
                {/*    }*/}
                {/*    disabled={this.props.disabled}*/}
                {/*    // visible={this.props.isControlled ? this.props.isOpen : this.state.isOpen}*/}
                {/*    hover*/}
                {/*    // offsetX={this.props.offsetX}*/}
                {/*    // offsetY={this.props.offsetY}*/}
                {/*    placement={this.props.direction || "bottom-start"}*/}
                {/*    node={this.props.node}*/}
                {/*/>*/}
              </div>
              <div
                className="br-12 p-2 mb-12 row-ac npo-nav-card pointer"
                style={{ marginRight: "auto" }}
                onClick={(e) => {
                  this.navigateToNPO();
                  e.stopPropagation();
                }}
              >
                <NPOPicture size={40} np={nonProfit} className={"mr-3"} />
                <div className="col-plain">
                  <p className="body3 black-50">A challenge for</p>
                  <p className="body2 black-100">{nonProfit.name}</p>
                </div>
                <SvgArrowForward className={"primary ml-12"} />
              </div>
              <p className="body2 black-100 mb-16">
                <RenderHtml htmlString={challenge.description || ""} />
              </p>
              {challenge.pledge_goal > 0 ? (
                <div className="bg-blue-500/10 rounded-xl p-3 mb-12">
                  <p className="text-lg font-medium text-blue-500">
                    <span className="font-semibold">Pledge:</span>{" "}
                    {challenge.pledge}
                  </p>
                </div>
              ) : null}
              {inPast ? (
                <div
                  className="mb-12 p-4 br-12"
                  style={{ backgroundColor: "#0088ff30" }}
                >
                  <h2 className="primary title2 m-0">Challenge Ended!</h2>
                  {!!challenge.donations_progress && (
                    <div className="row-ac pt-12 primary">
                      <SvgPiggy style={{ width: 20, height: 20 }} />
                      <p className="ml-8 body2-bold">
                        {`$${formatStatistics(
                          challenge.donations_progress,
                          1
                        )}`}{" "}
                        raised
                      </p>
                    </div>
                  )}
                  {!!challenge.awareness_progress && (
                    <div className="row-ac pt-12 primary">
                      <SvgMegaphone style={{ width: 20, height: 20 }} />
                      <p className="ml-8 body2-bold">
                        This cause gained {challenge.awareness_progress} new
                        followers
                      </p>
                    </div>
                  )}
                  {!!challenge.pledge_progress && (
                    <div className="row-ac pt-12 primary">
                      <SvgMegaphone style={{ width: 20, height: 20 }} />
                      <p className="ml-8 body2-bold">
                        This challenge had {challenge.pledge_progress} pledges
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col gap-3">
                  {challenge.donations_goal > 0 ? (
                    <div className="mb-12">
                      <ChallengeDonationBar
                        challenge={challenge}
                        matching={matching}
                      />
                    </div>
                  ) : null}
                  {challenge.awareness_goal > 0 ? (
                    <div>
                      <ChallengeAwarenessBar
                        challenge={challenge}
                        matching={matching}
                      />
                    </div>
                  ) : null}
                  {challenge.pledge_goal > 0 ? (
                    <div>
                      <ChallengePledgeBar challenge={challenge} />
                    </div>
                  ) : null}
                </div>
              )}
              {matching > 0 ? (
                <div
                  className="row-ac p-2 br-8 mt-12"
                  style={{ backgroundColor: "#F8969C20", color: "#F8969C" }}
                >
                  <SvgFire style={{ width: 20, height: 20, minWidth: 20 }} />
                  <p className="body2 ml-12">
                    You've found a challenge with matching! This means that for
                    every dollar you contribute, a matching dollar with be
                    donated by Starfish / our partners. Just one more way to
                    make an even bigger impact.
                  </p>
                </div>
              ) : null}
            </div>
            <>
              <div className="mb-3">
                <SectionTabs
                  activeTab={this.state.activeTab}
                  changeTab={this.changeTab}
                  tabs={["Challenge Activity", "Posts"]}
                />
              </div>
              {this.state.activeTab === "Challenge Activity" && (
                <>
                  {/* {!!this.props.user && myActions.length > 0 ?
                                        <div className='border-b border-slate-200 mb-3'>
                                            {myActions.map(action => {
                                                if (action.type === 'donations') {
                                                    return (
                                                        <DonationCard action={action}/>
                                                    )
                                                } else if (action.type === 'awareness') {
                                                    return (
                                                        <AwarenessCard action={action}/>
                                                    )
                                                }
                                                return (
                                                    <PledgeCard action={action}/>
                                                )
                                            })}
                                        </div>
                                        : null} */}
                  {this.state.actions.length === 0 && (
                    <div className="col-ac-jc pv-24">
                      <p className="body2 black-50">No activity yet.</p>
                    </div>
                  )}
                  {this.state.actions.map((action) => {
                    if (action.type === "donations") {
                      return <DonationCard action={action} />;
                    } else if (action.type === "awareness") {
                      return <AwarenessCard action={action} />;
                    }
                    return <PledgeCard action={action} />;
                  })}
                </>
              )}
              {this.state.activeTab === "Posts" && (
                <>
                  {this.state.posts.map((post) => {
                    return <PostComponent post={post} />;
                  })}
                  {this.state.posts.length === 0 ? (
                    <ZeroStateArea
                      title={"Nothing here..."}
                      sub={`${nonProfit.name} has not made any posts yet. Tag them in a post to get them started!`}
                    />
                  ) : null}
                </>
              )}
            </>
          </div>
        )}
        <AuthModal
          isOpen={this.state.authModalOpen}
          closeModal={this.closeAuthModal}
          mobile={this.props.mobile}
          createCheckout={this.createCheckout}
        />
        <DonateModal
          isOpen={this.state.donateModalOpen}
          closeModal={this.closeDonateModal}
          openAuthModal={this.openAuthModal}
          mobile={this.props.mobile}
          challenge={challenge}
          nonProfit={nonProfit}
          updateAmount={this.updateAmount}
          memo={this.state.memo}
          onChangeMemo={this.onChangeMemo}
        />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
