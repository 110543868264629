import * as React from "react";

const SvgChart = (props) => (
  <svg
    width="206"
    height="222"
    viewBox="0 0 206 222"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <g clip-path="url(#clip0_2336_3)">
      <path
        d="M155.713 46.3316L172.42 44.447C128.458 105.888 76.1657 124.578 10.2903 131.684L17.5875 166.11C102.145 158.792 167.105 92.6815 180.257 46.2586L191.526 56.7859C191.526 56.7859 189.141 21.5966 186.196 20.2662C183.251 18.9357 155.713 46.3316 155.713 46.3316Z"
        fill="#3B82F6"
      />
      <path
        d="M15.6687 161.403C15.0717 161.403 14.4934 161.195 14.0323 160.816C13.5711 160.437 13.2557 159.91 13.1397 159.325L7.75881 132.185C7.68912 131.834 7.6933 131.472 7.77108 131.123C7.84887 130.774 7.99855 130.444 8.2106 130.156C8.42265 129.868 8.69243 129.627 9.00277 129.448C9.31311 129.27 9.65722 129.158 10.0132 129.119C72.5178 122.378 123.304 105.786 166.863 47.6698L156.003 48.8949C155.494 48.9525 154.981 48.8578 154.527 48.6229C154.072 48.388 153.698 48.0235 153.452 47.5757C153.205 47.1279 153.097 46.617 153.142 46.1078C153.187 45.5987 153.382 45.1142 153.702 44.716C153.785 44.6128 162.098 34.2827 170.632 24.0859C188.64 2.57007 189.913 2.57007 191.525 2.57007C192.305 2.57007 193.039 2.88988 193.591 3.47092C194.627 4.56263 195.802 5.79988 195.086 32.0178C194.746 44.4798 194.108 56.7966 194.102 56.9193C194.077 57.4099 193.912 57.8831 193.626 58.283C193.341 58.683 192.947 58.9932 192.491 59.1771C192.035 59.361 191.537 59.4109 191.053 59.321C190.57 59.2312 190.123 59.0052 189.764 58.6698L181.493 50.9424C177.827 61.6458 171.463 72.9825 162.921 83.9832C153.168 96.5443 140.496 108.749 126.277 119.277C110.951 130.625 94.0896 139.978 76.1607 147.074C56.6283 154.804 36.3518 159.622 15.8939 161.393C15.8191 161.399 15.7441 161.403 15.6691 161.402L15.6687 161.403ZM13.3675 133.938L17.7498 156.042C97.0602 148.233 164.348 92.945 177.774 45.5557C177.895 45.1301 178.123 44.7427 178.436 44.4307C178.75 44.1186 179.138 43.8924 179.565 43.7738C179.991 43.6551 180.44 43.648 180.87 43.7531C181.3 43.8583 181.695 44.0721 182.019 44.3741L189.222 51.1038C190.008 34.2211 190.394 17.0379 189.951 9.80379C184.407 15.5331 171.872 30.5101 161.673 43.0639L172.131 41.8841C172.627 41.8282 173.128 41.9171 173.574 42.14C174.02 42.363 174.392 42.7105 174.645 43.1405C174.898 43.5704 175.02 44.0644 174.998 44.5626C174.976 45.0607 174.809 45.5417 174.519 45.9473C153.409 75.4496 129.268 96.6479 100.716 110.753C76.6675 122.633 49.6322 129.819 13.3675 133.938Z"
        fill="#231F20"
      />
    </g>
    <g clip-path="url(#clip1_2336_3)">
      <path
        d="M201.312 88.8478C204.871 93.8457 202.474 210.584 198.988 212.257C195.502 213.929 175.36 215.634 171.099 212.257C166.838 208.879 166.838 92.3437 171.099 88.8478C175.36 85.3518 197.438 83.4095 201.312 88.8478Z"
        fill="white"
      />
      <path
        d="M182.618 216.815C177.086 216.815 171.842 216.158 169.492 214.296C168.435 213.458 167.237 212.509 166.312 196.048C165.803 186.977 165.472 174.883 165.356 161.076C165.213 143.997 165.424 126.541 165.937 113.186C166.868 88.9622 168.304 87.7839 169.458 86.8369C173.482 83.5343 186.309 82.1559 194.248 83.2162C198.886 83.8358 201.886 85.1837 203.42 87.338C204.082 88.2665 205.081 89.6701 205.494 108.146C205.724 118.419 205.714 132.267 205.468 147.14C205.017 174.395 203.779 204.938 202.034 211.734C201.849 212.453 201.464 213.95 200.106 214.601C197.947 215.637 190.026 216.815 182.618 216.815L182.618 216.815ZM173.025 210.411C174.23 211.006 177.868 211.816 185.242 211.553C190.667 211.361 195.178 210.694 197.095 210.175C198.183 205.628 199.589 184.149 200.224 150.549C200.889 115.295 200.153 93.3502 199.112 90.2496C198.767 89.8769 197.509 88.895 193.563 88.3678C186.005 87.3585 175.722 89.0049 173.046 90.645C171.79 94.2701 170.495 116.022 170.494 150.55C170.493 184.939 171.775 206.655 173.025 210.411Z"
        fill="#231F20"
      />
      <path
        d="M148.777 111.031C152.337 115.145 149.939 211.224 146.453 212.601C142.968 213.977 122.825 215.38 118.565 212.601C114.304 209.821 114.304 113.908 118.565 111.031C122.825 108.154 144.904 106.555 148.777 111.031Z"
        fill="white"
      />
      <path
        d="M130.096 216.824C124.64 216.824 119.469 216.29 117.152 214.779C115.709 213.838 114.619 212.128 113.735 198.639C113.227 190.89 112.897 180.227 112.805 168.616C112.695 154.798 112.927 140.816 113.441 130.254C113.719 124.529 114.071 119.938 114.488 116.607C115.171 111.139 115.939 109.671 117.118 108.876C120.001 106.928 127.543 105.866 133.737 105.676C139.888 105.489 147.828 105.969 150.735 109.328C151.487 110.198 152.518 111.388 152.947 126.423C153.181 134.637 153.185 145.774 152.958 157.785C152.545 179.597 151.37 204.71 149.674 211.646C149.44 212.604 149.004 214.387 147.403 215.019C145.266 215.862 137.424 216.824 130.096 216.824ZM120.378 210.619C121.699 211.141 125.374 211.779 132.724 211.561C138.142 211.403 142.673 210.852 144.655 210.417C145.726 206.227 147.092 187.849 147.689 161.818C148.339 133.522 147.657 115.571 146.671 112.593C145.624 111.752 141.492 110.637 133.895 110.871C127.054 111.08 122.091 112.178 120.396 112.975C119.192 116.348 117.962 134.117 117.96 161.814C117.959 189.419 119.179 207.162 120.378 210.619Z"
        fill="#231F20"
      />
      <path
        d="M96.2433 145.312C99.8029 148.059 97.4052 212.214 93.9193 213.133C90.4333 214.052 70.2913 214.989 66.0305 213.133C61.7698 211.277 61.7698 147.234 66.0305 145.312C70.2913 143.391 92.3697 142.323 96.2433 145.312Z"
        fill="white"
      />
      <path
        d="M77.5282 216.804C72.2014 216.804 67.1825 216.468 64.9981 215.516C63.1516 214.712 61.9889 212.507 61.1338 203.175C60.617 197.535 60.3035 189.905 60.2513 181.693C60.1943 172.741 60.4575 163.813 60.9735 157.199C61.9053 145.26 63.3334 143.678 64.9674 142.942C67.7552 141.685 75.1435 140.997 81.2293 140.873C89.964 140.694 95.5451 141.496 97.8232 143.252C98.9648 144.133 101.09 145.773 100.5 173.447C100.225 186.399 99.2743 202.804 97.7728 210.258C97.2631 212.788 96.6353 215.104 94.5782 215.646C92.5285 216.187 84.7463 216.804 77.5282 216.804ZM67.6004 210.931C71.7949 212.068 87.5052 211.554 92.3305 210.802C93.213 207.882 94.5371 197.125 95.1542 179.23C95.7639 161.56 95.2101 149.838 94.3557 147.202C93.0449 146.625 88.7872 145.914 81.2948 146.07C75.051 146.2 69.6575 146.875 67.6119 147.495C66.6546 149.983 65.4275 160.833 65.426 179.221C65.4249 197.567 66.6446 208.41 67.6001 210.931H67.6004Z"
        fill="#231F20"
      />
      <path
        d="M43.7093 178.383C47.269 179.811 44.8713 213.168 41.3854 213.646C37.8994 214.123 17.7573 214.611 13.4966 213.646C9.23584 212.68 9.23584 179.382 13.4966 178.383C17.7573 177.384 39.8358 176.829 43.7093 178.383Z"
        fill="white"
      />
      <path
        d="M25.1005 216.803C19.8566 216.803 14.9432 216.637 12.9261 216.18C10.1169 215.544 9.147 211.913 8.56878 208.445C8.03239 205.229 7.72774 200.959 7.71071 196.423C7.69331 191.749 7.97983 187.307 8.51733 183.918C9.32986 178.795 10.6429 176.383 12.9073 175.852C17.4271 174.793 40.1475 174.156 44.6722 175.97C46.5678 176.73 47.8657 178.894 48.0397 186.983C48.1411 191.694 47.819 197.625 47.1786 202.847C46.7755 206.134 46.2676 208.948 45.7094 210.984C45.0486 213.394 44.0595 215.902 41.7367 216.22C39.6192 216.51 32.0684 216.803 25.1009 216.803L25.1005 216.803ZM14.6119 211.21C19.1185 211.885 35.3333 211.589 40.2078 211.16C40.9448 209.451 42.0713 204.101 42.6158 196.024C43.1778 187.692 42.7391 182.307 42.1935 180.634C37.9279 179.672 19.6685 179.927 14.6227 180.806C13.8708 182.303 12.8932 187.715 12.8921 196.013C12.891 204.289 13.8616 209.695 14.6119 211.21Z"
        fill="#231F20"
      />
    </g>
    <defs>
      <clipPath id="clip0_2336_3">
        <rect
          width="187.59"
          height="163.54"
          fill="white"
          transform="translate(7.70996 2.57007)"
        />
      </clipPath>
      <clipPath id="clip1_2336_3">
        <rect
          width="197.95"
          height="133.94"
          fill="white"
          transform="translate(7.70996 82.8601)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgChart;
