import React, { PureComponent } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Main from "./pages/Main";
import About from "./pages/About";
import CommunityGuidelines from "./pages/CommunityGuidelines";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar";
import { getUser } from "./api/users";
import PublicProfilePage from "./pages/PublicProfilePage";
import NonProfits from "./pages/NonProfits";
import PublicNonProfitPage from "./pages/PublicNonProfitPage";
import Footer from "./components/Footer";
import AuthPage from "./pages/AuthPage";
import CampaignPage from "./pages/CampaignPage";
import CampaignFeatureModal from "./components/CampaignFeatureModal";
import NavMenu from "./components/NavMenu";
import DonationSuccessPage from "./pages/DonationSuccessPage";
import AppMain from "./components/layout/AppMain";
import OnboardingMain from "./pages/Onboarding/OnboardingMain";
import PublicPostPage from "./pages/PublicPostPage";
import PublicChallengePage from "./pages/PublicChallengePage";
import StarfishLabPage from "./pages/StarfishLabPage";
import GroupPreviewPage from "./pages/GroupPreviewPage";
import AuthPageBinghamton from "./pages/binghamton/AuthPageBinghamton";
import SecurityAndSafety from "./pages/SecurityAndSafety";
import StarfishStudioPage from "./pages/StarfishStudioPage";

function mapDispatchToProps(dispatch) {
  return {
    setUser: (user) => {
      dispatch({
        type: "SET_USER",
        user,
      });
    },
    setAuthUser: (user) => {
      dispatch({
        type: "SET_AUTH_USER",
        user,
      });
    },
    setMobile: (mobile) => {
      dispatch({
        type: "SET_MOBILE",
        mobile,
      });
    },
    updateFollowees: (followees) => {
      dispatch({
        type: "UPDATE_FOLLOWEES",
        followees,
      });
    },
    updateInterests: (interests) => {
      dispatch({
        type: "UPDATE_INTERESTS",
        interests,
      });
    },
  };
}

function mapStateToProps(state) {
  return {
    userData: state.userReducer.user,
  };
}

class Routes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      initializing: true,
      user: {},
      campaignFeatureModalOpen: false,
      navOpen: false,
    };
  }

  componentDidMount() {
    this.userUpdated();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.userUpdated();
    }
  }

  resize() {
    this.props.setMobile(window.innerWidth <= 760);
    this.setState({
      mobile: window.innerWidth <= 760,
    });
  }

  userUpdated = async () => {
    if (this.props.user !== 0) {
      if (this.props.user) {
        this.setState({ initializing: true });
        this.props.setAuthUser(this.props.user);

        const user = await getUser(this.props.user);
        this.props.setUser(user.user);

        const followees = [];
        for (const followee of Object.keys(user.user.followees)) {
          followees.push(followee);
        }
        this.props.updateFollowees(followees);

        const interests = [];
        for (const interestInfo of user.user.interests) {
          interests.push(interestInfo);
        }
        this.props.updateInterests(interests);

        this.setState({ initializing: false });

        window.heap.identify(user.user.username);
        window.heap.addUserProperties({
          email: user.user.email,
          firstName: user.user.first_name,
          lastName: user.user.last_name,
        });
      } else {
        this.props.setUser(null);
        this.setState({ initializing: false });
      }
    }
  };

  openCampaignFeatureModal = () => {
    this.setState({ campaignFeatureModalOpen: true });
  };

  closeCampaignFeatureModal = () => {
    this.setState({ campaignFeatureModalOpen: false });
  };

  toggleNavMenu = () => {
    if (!this.state.navOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    this.setState({ navOpen: !this.state.navOpen });
  };

  render() {
    if (this.state.initializing) {
      return (
        <div className="col-ac-jc" style={{ width: "100vw", height: "100vh" }}>
          <img src={require("./img/brand/logo.png")} className="logo-spin" />
        </div>
      );
    }
    if (!this.props.user) {
      return (
        <div className="col-plain relative h-[100dvh">
          <ScrollToTop />
          <Navbar toggleNavMenu={this.toggleNavMenu} />
          <Switch>
            <Route exact path="/" component={Main} />
            <Route path="/non-profits" component={NonProfits} />
            <Route path="/about" component={About} />
            <Route path="/security-and-safety" component={SecurityAndSafety} />
            {/* <Route path="/starfishlab" component={StarfishLabPage}/> PREVIOUS PAGE*/}
            <Route path="/starfishstudio" component={StarfishStudioPage} />
            <Route
              path="/community-guidelines"
              component={CommunityGuidelines}
            />
            <Route path="/campaign" component={CampaignPage} />
            <Route path="/thanks" component={DonationSuccessPage} />
            <Route
              path="/authentication"
              render={(props) => <AuthPage {...props} />}
            />
            <Route
              path="/authentication-binghamton"
              render={(props) => <AuthPageBinghamton {...props} />}
            />
            <Route path="/post" component={PublicPostPage} />
            <Route path="/profile" component={PublicProfilePage} />
            <Route path="/non-profit" component={PublicNonProfitPage} />
            <Route path="/challenge" component={PublicChallengePage} />
            <Route
              path="/group-preview/:groupId"
              component={GroupPreviewPage}
            />
            <Route
              exact
              path="/invite/:groupId"
              render={(props) => (
                <Redirect
                  to={`/authentication?path=invite/${props.match.params.groupId}`}
                />
              )}
            />
            <Route
              exact
              path="/referral/:referralId"
              render={(props) => (
                <Redirect
                  to={`/authentication?path=referral/${props.match.params.referralId}&activeTab=signup`}
                />
              )}
            />
            <Redirect to="/authentication" />
          </Switch>
          <Footer />
          <CampaignFeatureModal
            isOpen={this.state.campaignFeatureModalOpen}
            closeModal={this.closeCampaignFeatureModal}
          />
          <NavMenu
            navOpen={this.state.navOpen}
            toggleNavMenu={this.toggleNavMenu}
          />
        </div>
      );
    }
    if (!this.props.userData?.onboarded) {
      return (
        <Switch>
          <Route
            exact
            path="/onboarding/invite/:groupId"
            render={(props) => (
              <Redirect
                to={`/onboarding?path=invite/${props.match.params.groupId}`}
              />
            )}
          />
          <Route
            exact
            path="/onboarding/referral/:inviteId"
            render={(props) => (
              <Redirect
                to={`/onboarding?path=referral/${props.match.params.inviteId}`}
              />
            )}
          />
          <Route
            exact
            path="/onboarding/non-profit/:ein"
            render={(props) => (
              <Redirect
                to={`/onboarding?path=non-profit/${props.match.params.ein}`}
              />
            )}
          />
          <Route path="/onboarding" component={OnboardingMain} />
          <Redirect to="/onboarding" />
        </Switch>
      );
    }
    return <AppMain />;
  }
}

Routes.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
