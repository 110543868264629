import React, {Component} from "react";
import SvgSupporter from "../icons/SvgSupporter";
import {withRouter} from "react-router-dom";
import SvgVolunteer from "../icons/SvgVolunteer";
import SvgAward from "../icons/SvgAward";
import NPOPicture from "../atoms/NPOPicture";
import SvgTrees from "../icons/categories/SvgTrees";
import {Button} from "../atoms/Button";
import SvgArrowForward from "../icons/ArrowForward";
import SvgCalendar from "../icons/SvgCalendar";
import SvgVerifiedDonor from "../icons/SvgVerifiedDonor";
import SvgEditNote from "../icons/SvgEditNote";
import SvgTrash from "../icons/SvgTrash";

class ProfileWorkItem extends Component {

    renderIcon = (role) => {
        switch (role) {
            case 'Supporter':
                return <SvgSupporter className={'w-5 h-5'}/>
            case 'Volunteer':
                return <SvgVolunteer className={'w-5 h-5'}/>
            case 'Employee':
                return <SvgAward className={'w-5 h-5'}/>
        }
    }

    selectColor = (role) => {
        switch (role) {
            case 'Supporter':
                return '#3b81f6'
            case 'Volunteer':
                return "#ea66c3"
            case 'Employee':
                return "#F17300"
        }
    }

    navigateToNPO = (name) => {
        this.props.history.push({pathname: `/non-profit/${name}`, state: {previous: "Back"}})
    }

    render() {
        const item = this.props.item
        const user = this.props.user
        return (
            <div
                className={`flex flex-col gap-3 border-b border-slate-200 last:border-transparent last:pb-0 py-5`}
                key={item.ein}
                style={{}}
                onError={(e) => {
                    e.target.src =
                        "https://handshake-production-cdn.joinhandshake.com/static_assets/default_institution_1.png";
                    this.setState({imageError: true});
                }}
                >
                <div className='flex flex-row flex-1 items-center justify-between gap-2'>
                    <NPOPicture
                        logoUrl={item.logo_url}
                        size={64}
                        np={item}
                        className={'border min-w-[64px] border-slate-200'}
                    />
                    <div className='col-plain flex flex-1 truncate' style={{textAlign: "left"}}>
                        <p className='text-xl font-semibold'>
                            {item.name}
                        </p>
                        <div className='flex flex-row items-center gap-2 text-slate-500'>
                            <SvgTrees className={'w-6 h-6'}/>
                            <p className='font-semibold text-lg truncate'>
                                {item.category}
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-row items-center gap-3'>
                        {this.props.editing ?
                            <Button size={'icon'} variant={'secondary'} onClick={() => this.props.onClickEdit(item)}>
                                <SvgEditNote/>
                            </Button>
                            :
                            <Button size={'icon'} variant={'secondary'} onClick={() => this.navigateToNPO(item.name)}>
                                <SvgArrowForward />
                            </Button>
                        }
                        {this.props.editing &&
                            <Button size={'icon'} variant={'destructive'} onClick={() => this.props.onClickDelete(item.ein)}>
                                <SvgTrash/>
                            </Button>
                        }
                    </div>
                </div>
                <div className='flex flex-col flex-1 gap-1'>
                    <div className="flex flex-row gap-5">
                        <div className='flex flex-row items-center text-slate-500'
                             style={{color: this.selectColor(item.role)}}>
                            {this.renderIcon(item.role)}
                            <p className='font-semibold ml-4'>
                                {item.role}
                            </p>
                        </div>
                        {item.donor ?
                            <div className='flex flex-row items-center text-emerald-500'>
                                <SvgVerifiedDonor className={'w-6 h-6'}/>
                                <p className='font-semibold ml-4'>
                                    Donor
                                </p>
                            </div>
                            : null}
                        <div className='flex flex-row items-center text-slate-500'>
                            <SvgCalendar className={'w-5 h-5'}/>
                            <p className='font-semibold ml-4'>
                                {item.date} - {item.end_date || "Present"}
                            </p>
                        </div>
                    </div>
                    <p className='text-slate-700 w-full mt-1 text-lg'>
                        {item.connection}
                    </p>
                </div>
            </div>
        )
    }
}

export default withRouter(ProfileWorkItem);
