import React, {PureComponent} from "react";

class StudioSection4 extends PureComponent {
    render() {
        const header = "Ready to start growing your Nonprofit’s supporters?";
        const para1 = "Let the team at Starfish Studio create a custom growth strategy for your Nonprofit today! The steps are easy:";
        return (
            <div
                className="mt-20 md:mt-0 flex flex-col justify-center items-center gap-y-5 min-h-[100vh] md:min-h-[80vh] max-w-[350px] sm:max-w-xl md:max-w-3xl xl:max-w-5xl self-center">
                <div className="flex flex-row gap-x-2 items-center md:gap-x-10">
          <span className="text-center text-2xl md:text-4xl xl:text-5xl text-starfish-black-900 font-bold">
            {header}
          </span>
                </div>
                <span className="text-center text-xl xl:text-2xl text-starfish-black-600">
          {para1}
        </span>

                <div className={'grid grid-cols-1 md:grid-cols-2 gap-3'}>
                    <div className={'flex flex-col p-4 border border-slate-200 rounded-2xl relative overflow-hidden'}>
                        <p className={'text-3xl font-bold opacity-50'}>
                            1)
                        </p>
                        <p className={'text-7xl font-black opacity-10 absolute -top-4 right-0'}>
                            STEP 1
                        </p>
                        <p className={'text-xl mb-20 mt-1'}>
                            Be sure you’re already signed up on Starfish. It’s free.
                            If you need to sign up, click below and create your account.
                        </p>
                        <a
                            style={{textDecoration: "none"}}
                            href="/authentication?activeTab=signup"
                            target={"_blank"}
                            className="row-ac primary-button body1-bold"
                        >
                            Create your Starfish account
                        </a>
                    </div>
                    <div className={'flex flex-col p-4 border border-slate-200 rounded-2xl relative overflow-hidden'}>
                        <p className={'text-3xl font-bold opacity-50'}>
                            2)
                        </p>
                        <p className={'text-7xl font-black opacity-10 absolute -top-4 right-0'}>
                            STEP 2
                        </p>
                        <p className={'text-xl mb-20 mt-1'}>
                            If you already have a Starfish Account, just click below and schedule a consultation to
                            learn
                            more.
                        </p>
                        <a
                            style={{textDecoration: "none"}}
                            href="mailto:support@starfishapp.com"
                            target={"_blank"}
                            className="row-ac primary-button body1-bold md:mt-auto"
                        >
                            Schedule a Consultation
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default StudioSection4;
