import React from "react";
import SvgUpRightArrow from "../icons/UpRight";

function ViewMoreBanner({ post, onClick, isAbsolute=false }) {
  return post.challenge_id ? (
    <button
      style={{ textDecoration: "none" }}
      className="mb-3 px-4 py-2 rounded-xl items-center flex flex-row gap-x-1 text-sm w-full bg-[#EFA143] hover:cursor-pointer"
      onClick={onClick}
    >
      <p>View More</p>
      <SvgUpRightArrow />
    </button>
  ) : (
    <a
      style={{ textDecoration: "none" }}
      href={post.for_profit_url ? post.for_profit_url : `https://starfishapp.com/non-profit/${post.creator_name}`}
      className={`px-4 py-2 items-center flex flex-row gap-x-1 text-sm w-full bg-[#EFA143] hover:cursor-pointer z-50 ${isAbsolute ? "rounded-b-md" : "rounded-xl mb-3"}`}
      onClick={onClick}
    >
      <p>View More</p>
      <SvgUpRightArrow />
    </a>
  );
}

export default ViewMoreBanner;
