import React, { Component } from "react";
import AnimatedInput from "../components/AnimatedInput";
import ModalComponent from "../components/ModalComponent";
import { signInWithEmailAndPassword } from 'firebase/auth'
import Spinner from "../components/Spinner";
import { login, signUp } from '../api/authentication';
import { firebaseAuth } from "../api/firebase";
import { wait } from "../utils/timestamp"
import ForgotPassword from "./ForgotPassword";
import { firebaseAnalytics } from "../api/firebase";
import { hasLowercaseCharacter, hasUppercaseCharacter, hasDigit, validateEmail } from "../utils/strings";

class AuthModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            username: '',
            activeTab: 'login',
            forgotPasswordModal: false,
        }
    }

    toggleForgotPasswordModal = () => {
        this.setState({ forgotPasswordModal: !this.state.forgotPasswordModal })
    }

    onChangeFirstName = (firstName) => {
        this.setState({ firstName, error: "" })
    }

    onChangeLastName = (lastName) => {
        this.setState({ lastName, error: "" })
    }

    onChangeUsername = (username) => {
        const strWOSpaces = username.replace(/\s+/g, '');
        const finalStr = strWOSpaces.replace(/\W/g, '');
        this.setState({ username: finalStr.toLowerCase(), error: "" })
    }

    onChangeEmail = (email) => {
        this.setState({ email: email.toLowerCase(), error: "" })
    }

    onChangePassword = (password) => {
        this.setState({ password, error: "" })
    }

    closeModal = () => {
        this.props.closeContactUs()
    }

    logInPressed = async () => {
        let error;
        if (!this.state.email) {
            error = "Please enter an email"
        } else if (!validateEmail(this.state.email)) {
            error = "Please enter a valid email address."
        } else if (!this.state.password) {
            error = "Please enter a password"
        }
        if (error) {
            this.setState({ error })
            return
        }
        this.setState({ processing: true })

        try {
            await signInWithEmailAndPassword(firebaseAuth, this.state.email, this.state.password);
            console.log("LOGGED IN")
            this.props.closeModal()
        } catch (err) {
            this.setState({ error: "Incorrect username and password.", processing: false })
        }
    }

    signUpPressed = async () => {
        let error;
        const passLength = this.state.password.length >= 6;
        const passLowercase = hasLowercaseCharacter(this.state.password);
        const passUppercase = hasUppercaseCharacter(this.state.password);
        const passDigit = hasDigit(this.state.password);

        if (!this.state.email) {
            error = "Please enter an email"
        } else if (!this.state.password) {
            error = "Please enter a password"
        } else if (!this.state.firstName || !this.state.lastName) {
            error = "Please enter your first and last name"
        } else if (!this.state.username) {
            error = "Please enter a username"
        } else if (!validateEmail(this.state.email)) {
            error = "Please enter a valid email address."
        } else if (!passLength) {
            error = "Password must be at least 6 characters long."
        } else if (!passUppercase) {
            error = "Password must have at least one uppercase letter."
        } else if (!passLowercase) {
            error = "Password must have at least one lowercase letter."
        } else if (!passDigit) {
            error = "Password must have at least one number."
        }

        if (error) {
            this.setState({ error })
            return;
        }
        this.setState({ processing: true })

        try {
            await signUp(this.state.email, this.state.username, this.state.password, this.state.firstName, this.state.lastName);
            this.props.closeModal()
            await signInWithEmailAndPassword(firebaseAuth, this.state.email, this.state.password);
            // firebaseAnalytics.logEvent('web_sign_up_completed', {})
            // wait(1000).then(() => {
            //     this.props.createCheckout()
            // })
            console.log("SIGNED UP")
        } catch (err) {
            this.setState({ error: "Failure on sign up", processing: false })
        }
    }

    onSubmit = () => {
        // firebaseAnalytics.logEvent('web_test', {})
        if (this.state.activeTab === "login") {
            this.logInPressed();
        } else {
            this.signUpPressed();
        }
    }

    render() {
        const buttonDisabled = false;
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                size={this.props.mobile ? 'full' : 'sm'}
                toggle={this.props.closeModal}
                headerToggle
            >
                <div className={`col-jc flex-1 ${this.props.mobile ? 'p-3' : 'p-5'} pt-0`}>
                    <h1 className={`${this.props.mobile ? 'title1' : 'heading2'} black-80 mb-0 mt-0 text-center`}>
                        {this.state.activeTab === 'login' ? 'Log In' : 'Sign Up'}
                    </h1>
                    {this.state.activeTab === 'signup' &&
                        <div className='mt-40 row-ac body1' style={{ marginTop: this.props.mobile && 24 }}>
                            <div className='flex-1 w-100p mr-4'>
                                <AnimatedInput
                                    name="firstName"
                                    onChange={this.onChangeFirstName}
                                    value={this.state.firstName}
                                    type="text"
                                    className={'w-100p'}
                                    placeholder="First Name"
                                />
                            </div>
                            <div className='flex-1 w-100p ml-4'>
                                <AnimatedInput
                                    name="lastName"
                                    onChange={this.onChangeLastName}
                                    value={this.state.lastName}
                                    type="text"
                                    className={'w-100p'}
                                    placeholder="Last Name"
                                />
                            </div>
                        </div>
                    }
                    {this.state.activeTab === "signup" ?
                        <div className='mt-40 row-ac body1' style={{ marginTop: this.props.mobile && 24 }}>
                            <div className='flex-1'>
                                <AnimatedInput
                                    name="username"
                                    onChange={this.onChangeUsername}
                                    value={this.state.username}
                                    type="username"
                                    placeholder="Username"
                                />
                            </div>
                        </div>
                        : null}
                    <div className='mt-40 row-ac body1' style={{ marginTop: this.props.mobile && 24 }}>
                        <div className='flex-1'>
                            <AnimatedInput
                                name="email"
                                onChange={this.onChangeEmail}
                                value={this.state.email}
                                type="email"
                                placeholder="Your Email"
                            />
                        </div>
                    </div>
                    <div className='mt-40 row-ac body1' style={{ marginTop: this.props.mobile && 24 }}>
                        <div className='flex-1'>
                            <AnimatedInput
                                name="password"
                                onChange={this.onChangePassword}
                                value={this.state.password}
                                type="password"
                                placeholder="Your Password"
                            />
                        </div>
                    </div>
                    {!!this.state.error ? <p className="body1" style={{ color: "red" }}>{this.state.error}</p> : null}
                    <div className='row-ac-jc mt-40 mb-12'>
                        <button disabled={buttonDisabled} onClick={this.onSubmit} className='primary-button body1-bold'
                            style={{ width: 200, opacity: buttonDisabled && .3 }}>
                            {this.state.processing ?
                                <Spinner /> : (this.state.activeTab === 'login' ? 'Log In' : 'Sign Up')}
                        </button>
                    </div>
                    <div className='col-ac mb-12'>
                        {this.state.activeTab === 'login' ?
                            <p className='body3 black-50'>
                                Don't have an account yet?
                                <span className='primary pointer'
                                    onClick={() => this.setState({ activeTab: 'signup', email: '', password: '' })}>
                                    &nbsp;Sign Up
                                </span>
                            </p>
                            :
                            <p className='body3 black-50'>
                                Already have an account?
                                <span className='primary pointer' onClick={() => this.setState({
                                    activeTab: 'login',
                                    email: '',
                                    password: '',
                                    firstName: "",
                                    username: "",
                                    lastName: ""
                                })}>
                                    &nbsp;Log In
                                </span>
                            </p>
                        }
                    </div>
                    {this.state.activeTab === "login" ?
                        <div className="col-ac mt40">
                            <span className="primary pointer body3" onClick={this.toggleForgotPasswordModal}>Forgot password?</span>
                        </div>
                        : null}
                    <ForgotPassword isOpen={this.state.forgotPasswordModal} mobile={this.props.mobile}
                        closeModal={this.toggleForgotPasswordModal} />
                </div>
            </ModalComponent>
        )
    }

}


export default AuthModal;
