import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import SvgPin from "../components/icons/SvgPin";
import {searchNonProfits, searchUsers} from "../utils/typesense";
import {getDefaultSuggestions, getSuggestions} from '../api/users';
import ProfilePicture from "../components/atoms/ProfilePicture";
import {connect} from "react-redux";
import SvgSearch from "../components/icons/SvgSearch";
import LoadingSection from "../components/LoadingSection";
import MiddleViewHeader from "../components/layout/MiddleViewHeader";
import {CARD_STYLE} from "../utils/dummy";
import SectionTabs from "../components/SectionTabs";
import {Button} from "../components/atoms/Button";
import NPOPicture from "../components/atoms/NPOPicture";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
        mobile: state.userReducer.mobile,
        interests: state.userReducer.interests,
        followees: state.userReducer.followees
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addInterest: (interest) => {
            dispatch({
                type: "ADD_INTEREST",
                interest,
            })
        },
        removeInterest: (interest) => {
            dispatch({
                type: "REMOVE_INTEREST",
                interest,
            })
        },
    }
}

class SearchPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            search: '',
            nonProfits: [],
            users: [],
            activeTab: 'Nonprofits',
        }
    }

    componentDidMount = async () => {
        this.updateSuggestions();
    }

    updateSuggestions = async () => {
        this.setState({loading: true})
        if (!!this.props.user) {
            const suggestions = await getSuggestions(this.props.authUser);
            suggestions.users = suggestions.users.filter(user => !this.props.user.uninterested_users.includes(user.username))
            suggestions.non_profits = suggestions.non_profits.filter(non_profit => !this.props.user.uninterested_non_profits.includes(non_profit.ein))
            this.setState({nonProfits: suggestions.non_profits, loading: false, users: suggestions.users});
        } else {
            const suggestions = await getDefaultSuggestions(this.props.authUser);
            this.setState({nonProfits: suggestions.non_profits, loading: false});
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.user !== prevProps.user) {
            this.updateSuggestions();
        }
    }


    searchChange = (e) => {
        this.setState({search: e.target.value})
        if (this.state.activeTab === "Nonprofits") {
            searchNonProfits(e.target.value).then(nonProfitResults => {
                this.setState({nonProfits: nonProfitResults, loading: false});
            });
        } else {
            searchUsers(e.target.value).then(userResults => {
                this.setState({users: userResults, loading: false});
            });
        }

    }

    navigateToNPO = (name) => {
        this.props.history.push({pathname: `/non-profit/${name}`, state: {previous: "Back"}})
    }

    navigateToUser = (slug) => {
        this.props.history.push({pathname: `/profile/${slug}`, state: {previous: "Back"}})
    }

    changeTab = (tab) => {
        this.setState({activeTab: tab});
    };

    render() {
        const TABS = ['Nonprofits', 'Users'];
        const interests = this.props.interests;
        return (
            <div className='flex flex-col flex-1 overflow-hidden'>
                <MiddleViewHeader
                    title={'Search'}
                    className={'pb-0'}
                >
                    <div className='row-ac relative'>
                        <input
                            onChange={this.searchChange}
                            value={this.state.search}
                            type="text"
                            className={'w-100p search-input rounded-lg'}
                            placeholder="Search..."
                        />
                        <SvgSearch className={'absolute black-50'}
                                   style={{top: 10, left: 24, width: 24, height: 24}}/>
                    </div>
                    <SectionTabs
                        activeTab={this.state.activeTab}
                        changeTab={this.changeTab}
                        tabs={TABS}
                    />
                </MiddleViewHeader>
                {this.state.loading ?
                    <LoadingSection/>
                    :
                    <>
                        {this.state.activeTab === 'Nonprofits' &&
                            <>
                                {(this.state.search.length > 0 && this.state.nonProfits.length === 0) ?
                                    <div
                                        className='flex flex-col items-center justify-center flex-1 text-slate-400 pv-40'>
                                        <p>
                                            No results for this search. Try something else.
                                        </p>
                                    </div>
                                    :
                                    <div className='p-5 flex flex-col flex-1 overflow-y-scroll'>
                                        {this.state.nonProfits.map((np, index) =>
                                            <div
                                            className={`${CARD_STYLE} flex flex-row items-center`} key={np.ein}
                                         onClick={() => this.navigateToNPO(np.name)}>
                                        <NPOPicture
                                            size={64}
                                            np={np}
                                            logoUrl={np.logo_url}
                                            className={'border border-slate-200'}
                                        />
                                        <div className='flex flex-col flex-1 ml-12' style={{textAlign: "left"}}>
                                            <p className='text-lg text-slate-800 font-semibold'>
                                                {np.name}
                                            </p>
                                            <p className='text-md text-slate-500'>
                                                {np.category}
                                            </p>
                                            <div className='row-ac'>
                                                <SvgPin className={'text-blue-500 w-3 h-3'}/>
                                                <p className='text-md text-blue-500 ml-4'>
                                                    {np.city}, {np.state}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='flex flex-row items-center gap-3'>
                                            {interests.some(interest => interest.id === np.ein) ?
                                                <Button
                                                    onClick={() => {
                                                        this.props.removeInterest({id: np.ein, name: np.name})
                                                    }}
                                                    variant={'default'}
                                                    size={'sm'}
                                                    radius={'full'}
                                                >
                                                    Unfollow
                                                </Button>
                                                :
                                                <Button
                                                    onClick={() => {
                                                        this.props.addInterest({id: np.ein, name: np.name})
                                                    }}
                                                    variant={'default'}
                                                    size={'sm'}
                                                    radius={'full'}
                                                >
                                                    Follow
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                    )
                                }
                                    </div>
                                }
                            </>
                        }

                        {this.state.activeTab === 'Users' &&
                            <>
                                {(this.state.search.length > 0 && this.state.nonProfits.length === 0) ?
                                    <div
                                        className='flex flex-col items-center justify-center flex-1 text-slate-400 pv-40'>
                                        <p>
                                            No results for this search. Try something else.
                                        </p>
                                    </div>
                                    :
                                    <div className='p-5 flex flex-col flex-1 overflow-y-scroll'>
                                        {this.state.users.map((user) =>
                                            <div
                                                className={`${CARD_STYLE} p-3 flex flex-row items-center`}
                                                key={user.username}
                                                onClick={() => this.navigateToUser(user.slug)}>
                                                <ProfilePicture
                                                    initial={user.first_name[0] + user.last_name[0]}
                                                    userID={user.username}
                                                    size={60}
                                                />
                                                <div className='flex flex-col flex-1 ml-12' style={{textAlign: "left"}}>
                                                    <p className='text-lg text-slate-800 font-semibold'>
                                                        {user.first_name} {user.last_name}
                                                    </p>
                                                    {!!user.tagline ?
                                                        <p className='text-slate-500 font-semibold -mt-1.5'>
                                                            {user.tagline}
                                                        </p>
                                                        : null}
                                                    <div className='row-ac'>
                                                        <SvgPin className={'text-blue-500 w-3 h-3'}/>
                                                        <p className='text-md text-blue-500 ml-4'>
                                                            {user.city}, {user.state}
                                                        </p>
                                                    </div>
                                                </div>
                                                {this.props.followees.includes(user.username) ?
                                                    <Button
                                                        onClick={() => {
                                                            this.props.removeFollowee(user.username)
                                                        }}
                                                        variant={'default'}
                                                        radius={'full'}
                                                        size={'sm'}
                                                    >
                                                        Unfollow
                                                    </Button>
                                                    :
                                                    <Button
                                                        onClick={() => {
                                                            this.props.addFollowee(user.username)
                                                        }}
                                                        variant={'default'}
                                                        radius={'full'}
                                                        size={'sm'}
                                                    >
                                                        Follow
                                                    </Button>
                                                }
                                            </div>
                                        )
                                        }
                                    </div>
                                }
                            </>
                        }
                    </>
                }
            </div>
        )
    }

}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchPage));
