import * as React from "react"

const SponsoredSwitch = React.forwardRef(
  ({ className, checked, onChange, ...props }, ref) => {
    const handleChange = () => {
      if (onChange) {
        onChange(!checked)
      }
    }

    return (
      <div className={`relative inline-flex items-center ${className}`} {...props} ref={ref}>
        <span
          onClick={handleChange}
          className={`peer inline-flex h-5 w-9 shrink-0 cursor-pointer items-center rounded-full border-2 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 ${checked ? 'bg-blue-200' : 'bg-transparent'}`}
        >
          <span
            className={`pointer-events-none block h-4 w-4 rounded-full bg-blue-500 shadow-lg transition-transform ${checked ? 'translate-x-4' : 'translate-x-0'}`}
          />
        </span>
      </div>
    )
  }
)

export default SponsoredSwitch;
