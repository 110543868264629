import React, { Component } from "react";
import { connect } from "react-redux";
import StudioSection1 from "./StarfishStudio/StudioSection1";
import StudioSection2 from "./StarfishStudio/StudioSection2";
import StudioSection3 from "./StarfishStudio/StudioSection3";
import StudioSection4 from "./StarfishStudio/StudioSection4";
import BGGraphic6 from "../img/graphics/BGGraphic6";

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    mobile: state.userReducer.mobile,
  };
}

class StarfishStudioPage extends Component {
  render() {
    return (
      <div className="flex flex-col pt-40 md:pt-100 w-[100vw] overflow-x-hidden">
        <BGGraphic6
          className="absolute black-10 -top-0 md:-top-12 opacity-70 max-w-[200px] md:max-w-xl"
          style={{ left: 0, zIndex: 0, transform: "rotate(12deg)" }}
        />
        <StudioSection1 />
        <StudioSection2 />
        <StudioSection3 />
        <StudioSection4 />
      </div>
    );
  }
}

export default connect(mapStateToProps)(StarfishStudioPage);
