import axios from "./axios";

export const getDonations = async (user) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/donations", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getPublicDonations = async (user, queried_user_id) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {queried_user_id}
        };
        const res = await axios.get("/donations/public", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const getDonationSummary = async (user, year) => {
    if (!user) {
        return;
    }
    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {year}
        };
        const res = await axios.get("/donations/summary", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
