import React, { Component } from "react";
import SvgClose from "../components/icons/SvgClose";
import { Link, withRouter } from "react-router-dom";
import FadeInOnScroll from "./FadeInOnScroll";
import { wait } from "@testing-library/user-event/dist/utils";
import SvgApple from "./icons/SvgApple";
import SvgGooglePlay from "./icons/SvgGooglePlay"


class NavMenu extends Component {

    navigate = (route) => {
        this.props.history.push(route)
        wait(150).then(() => {
            window.scrollTo({
                top: 0,
            })
            this.props.toggleNavMenu()
        }
        )
    }

    render() {
        return (
            <div className=' col-ac-jc bg-base-black-100'
                style={{
                    top: 0,
                    left: 0,
                    position: 'fixed',
                    right: 0,
                    height: this.props.navOpen ? '100vh' : '0vh',
                    // opacity: this.props.navOpen ? 1 : 0,
                    zIndex: 9999,
                    transition: "600ms ease-in-out",
                    overflow: "hidden",
                    backgroundColor: '#054A91'
                }}>
                <div className='absolute' style={{ top: 20, right: 24 }} onClick={this.props.toggleNavMenu}>
                    <div className='base-white-30 h-base-white-100 pointer mt-8 close-modal-icon' style={{
                        transform: (this.props.navOpen) ? 'rotate(0deg)' : 'rotate(-45deg)',
                        opacity: (this.props.navOpen) ? 1 : 0,
                        transition: '300ms ease-in-out',
                    }}>
                        <SvgClose fill={'white'} width={this.props.mobile ? 24 : this.props.tablet ? 24 : 36}
                            height={this.props.mobile ? 24 : this.props.tablet ? 24 : 36} />
                    </div>
                </div>
                {/*{this.props.navOpen &&*/}
                {/*    <FadeInOnScroll triggerImmediately delay={200}>*/}
                {/*        <div onClick={() => this.navigate('/')} className='pointer'>*/}
                {/*            <img alt={'logo'} style={{height: 80, marginBottom: 40}}*/}
                {/*                 src={require('../img/brand/LOGO-PRIMARY.png')}/>*/}
                {/*        </div>*/}
                {/*    </FadeInOnScroll>*/}
                {/*}*/}
                {this.props.navOpen &&
                    <FadeInOnScroll triggerImmediately delay={400}>
                        <div onClick={() => this.navigate('/')} className='pointer'>
                            <div className='white-100 heading3 pv-20 nav-menu-text'>
                                Home
                            </div>
                        </div>
                    </FadeInOnScroll>
                }
                {this.props.navOpen &&
                    <FadeInOnScroll triggerImmediately delay={600}>
                        <div onClick={() => this.navigate('/authentication')} className='pointer'>
                            <div className='white-100 heading3 pv-20 nav-menu-text'>
                                Login / Signup
                            </div>
                        </div>
                    </FadeInOnScroll>
                }
                {/* {this.props.navOpen &&
                    <FadeInOnScroll triggerImmediately delay={800}>
                        <div onClick={() => this.navigate('/challenges')} className='pointer'>
                            <div className='white-100 heading3 pv-20 nav-menu-text'>
                                Challenges
                            </div>
                        </div>
                    </FadeInOnScroll>
                } */}
                {this.props.navOpen &&
                    <FadeInOnScroll triggerImmediately delay={700}>
                        <div onClick={() => this.navigate('/about')} className='pointer'>
                            <div className='white-100 heading3 pv-20 nav-menu-text'>
                                About
                            </div>
                        </div>
                    </FadeInOnScroll>
                }
                {this.props.navOpen &&
                    <FadeInOnScroll triggerImmediately delay={900}>
                        <div onClick={() => this.navigate('/starfishlab')} className='pointer'>
                            <div className='white-100 heading3 pv-20 nav-menu-text'>
                                Starfish Lab
                            </div>
                        </div>
                    </FadeInOnScroll>
                }
                {this.props.navOpen &&
                    <FadeInOnScroll triggerImmediately delay={1100}>
                        <div onClick={() => this.navigate('/non-profits')} className='pointer'>
                            <div className='white-100 heading3 pv-20 nav-menu-text'>
                                For Nonprofits
                            </div>
                        </div>
                    </FadeInOnScroll>
                }
                {this.props.navOpen &&
                    <FadeInOnScroll triggerImmediately delay={1300}>
                        <div onClick={() => this.navigate('/starfishstudio')} className='pointer'>
                            <div className='white-100 heading3 pv-20 nav-menu-text'>
                                Starfish Studio
                            </div>
                        </div>
                    </FadeInOnScroll>
                }
                {/* {this.props.navOpen &&
                    <FadeInOnScroll triggerImmediately delay={1400}>
                        <div onClick={() => this.navigate('/campaign')} className='pointer'>
                            <div className='white-100 title1 pv-20 ph-32 mt-12 nav-menu-button'
                                 style={{border: '2px solid white', borderRadius: 1000}}>
                                Join the Million Dollar Challenge!
                            </div>
                        </div>
                    </FadeInOnScroll>
                } */}
                {this.props.navOpen &&
                    <FadeInOnScroll triggerImmediately delay={2000}>
                        <div className="flex flex-row row-ac">
                            <a style={{ textDecoration: 'none' }}
                                href='https://apps.apple.com/us/app/starfish-social/id1642351040' target={'_blank'}
                                className='primary-button bg-white row-ac body2-bold mt-12'>
                                <SvgApple width={24} height={24} />
                                <div className='ml-8' style={{ marginTop: 2 }}>
                                    iOS
                                </div>
                            </a>
                            <a style={{ textDecoration: 'none' }}
                                href='https://play.google.com/store/apps/details?id=com.starfishsocialmedia.app&hl=en_US&gl=US'
                                target={'_blank'}
                                className='primary-button bg-white row-ac body2-bold mt-12'>
                                <SvgGooglePlay className={'mr-8'} width={24} height={24} />
                                Android
                            </a>
                        </div>
                    </FadeInOnScroll>
                }
            </div>
        )
    }
}

export default withRouter(NavMenu)
