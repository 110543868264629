import SvgChevronDown from "../icons/SvgChevronDown";
import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { editGroup } from "../../api/groups";
import {withRouter} from "react-router-dom";
import {CARD_STYLE} from "../../utils/dummy";
import SvgPin from "../icons/SvgPin";
import {Button} from "../atoms/Button";
import NPOPicture from "../atoms/NPOPicture";
import SvgClose from "../icons/SvgClose";

function GroupNonProfitListItem({nonProfit, removable, group, isAdmin, history}) {
    const authUser = useSelector(state => state.userReducer.authUser);
    const [added, setAdded] = useState(false)

    const toggleAdded = () => {
        setAdded(!added);
        saveChanges();
    }

    const saveChanges = async () => {
        const currentNps = {...group.non_profits};
        if (nonProfit.ein in currentNps) {
            delete currentNps[nonProfit.ein]
        } else {
            currentNps[nonProfit.ein] = true;
        }
        await editGroup(authUser, group.id, {non_profits: currentNps});

    };

    const navigateToNPO = (name) => {
        history.push({pathname: `/non-profit/${name}`, state: {previous: "Back"}})
    }

    return (
        <div
            className={`${CARD_STYLE} p-2 flex flex-col shadow-none`}
            key={nonProfit.image_id}
        >
            <div className='flex flex-row items-center justify-between'>
                <div className='flex flex-row items-center gap-3' onClick={() => navigateToNPO(nonProfit.name)}>
                    <NPOPicture
                        size={64}
                        np={nonProfit}
                        className={'border border-slate-200'}
                    />
                    <div className='flex flex-col flex-1 ml-12' style={{textAlign: "left"}}>
                        <p className='text-lg text-slate-800 font-semibold'>
                            {nonProfit.name}
                        </p>
                        <p className='text-md text-slate-500'>
                            {nonProfit.category}
                        </p>
                        <div className='row-ac'>
                            <SvgPin className={'text-blue-500 w-3 h-3'}/>
                            <p className='text-md text-blue-500 ml-4'>
                                {nonProfit.city}, {nonProfit.state}
                            </p>
                        </div>
                    </div>
                </div>
                {isAdmin &&
                    <>
                    {removable ?
                        <Button size={'icon'} variant={'destructive'} disabled={added}
                                onClick={saveChanges}>
                            <SvgClose/>
                        </Button>
                        :
                        <Button size={'sm'} variant={added ? 'secondary' : 'default'} disabled={added}
                                onClick={toggleAdded}>
                            {added ? 'Added' : 'Add'}
                        </Button>
                    }
                    </>
                }
            </div>
            <p className='text-md font-medium text-slate-700 mt-3'>
                {nonProfit.description}
            </p>
        </div>
    )
}

export default withRouter(GroupNonProfitListItem);
