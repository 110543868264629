import React, { Component } from "react";
import ModalComponent from "../ModalComponent";
import { Button } from "../atoms/Button";

class AccountModal extends Component {


    render() {
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                size={this.props.mobile ? 'full' : 'lg'}
                toggle={this.props.closeModal}
                headerToggle
                FooterComponent={null
                }
            >
                <div className={`flex flex-col md:flex-row min-h-[30vh] items-center gap-x-8 ${this.props.mobile ? 'p-5' : 'p-5'} pt-0`} style={{ flex: 1 }}>
                    <div className='flex flex-col items-center text-center min-w-fit'>
                        <h1 className={`${this.props.mobile ? 'title1' : 'title1'} black-80 mb-12 mt-0`}>
                            Already have an account?
                        </h1>
                        <div className="flex flex-row items-center">
                            <div className='mt-24 flex flex-col gap-y-3 min-w-fit' style={{ marginTop: this.props.mobile && 24 }}>
                                <a href={this.props.url}
                                    target={'_blank'} rel={'noreferrer'} className="bg-blue-500 hover:bg-blue-600 text-white h-12 px-5 py-2 pb-2.5 text-[18px] font-bold rounded-full">Yes</a>
                                <Button
                                    onClick={this.props.donateOpen} className="text-sm md:text-lg"
                                >
                                    No, continue without an account
                                </Button>
                            </div>
                        </div>
                        <div className='flex flex-col items-center'>
                            <p className='text-lg black-100 mv-12'>
                                or you can <a
                                    href="https://www.starfishapp.com/authentication?activeTab=signup"
                                    target={'_blank'} rel={'noreferrer'} className="text-lg text-blue-500">create an account</a>
                            </p>
                        </div>

                    </div>
                    <div className="w-[1px] bg-zinc-400 h-48 hidden md:flex"></div>
                    <div className='flex flex-col items-center'>
                        <div className='text-md text-black mv-12 flex flex-col gap-y-3 leading-tight'>
                            <p className="text-blue-500 font-bold text-xl">Why Create an Account?</p>
                            <p className="text-black font-bold text-xl">Create a Starfish account and we'll donate another $1!</p>
                            <ul className="gap-y-2 list-disc pl-5 text-sm md:text-md">
                                <li>Connect with your favorite non-profits.</li>
                                <li>Find other individuals making an impact.</li>
                                <li>Share the good with the Starfish Network</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ModalComponent>
        )
    }

}


export default AccountModal;