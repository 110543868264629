import ProfilePicture from "../atoms/ProfilePicture";
import {Button} from "../atoms/Button";
import SvgUserPlus from "../icons/SvgUserPlus";
import SvgMessage from "../icons/SvgMessage";
import SvgMoney from "../icons/SvgMoney";
import SvgTarget from "../icons/SvgTarget";
import SvgCommunity from "../icons/SvgCommunity";
import SvgWave from "../icons/SvgWave";
import {CARD_STYLE} from "../../utils/dummy";
import NPOPicture from "../atoms/NPOPicture";
import SvgPin from "../icons/SvgPin";
import React from "react";
import {withRouter} from "react-router-dom";
import ChallengeCard from "../ChallengeCard";
import GroupSearchResultItem from "../groups/GroupSearchResultItem";
import {useSelector} from "react-redux";


function EventInFeedWidget(props) {
    const user = useSelector(state => state.userReducer.user);
    function RenderIcon({type}) {
        switch (type) {
            case 'joined':
                return <SvgWave className={'w-5 h-5'}/>
            case 'donated':
                return <SvgMoney className={'w-5 h-5'}/>
            case 'created_challenge':
                return <SvgTarget className={'w-5 h-5'}/>
            case 'joined_challenge':
                return <SvgTarget className={'w-5 h-5'}/>
            case 'created_group':
                return <SvgCommunity className={'w-5 h-5'}/>
            default:
                return null
        }
    }

    function RenderMessage({type, cause}) {
        switch (type) {
            case 'joined':
                return 'Just joined Starfish! Say hello!'
            case 'donated':
                return `Just donated to ${cause.name}!`
            case 'created_challenge':
                return 'Just created a challenge. Join in!'
            case 'joined_challenge':
                return 'Just participated in a challenge. Join in!'
            case 'created_group':
                return 'Just created a group. Join now!'
            default:
                return null
        }
    }

    function RenderButtons({type, activity, cause, challenge, group}) {
        switch (type) {
            case 'joined':
                return <>
                    <Button size={'sm'} variant={'white'} onClick={() => navigateToProfile(activity.username)}>
                        <SvgUserPlus className={'w-4 h-4 mr-1'}/> Follow {activity.name}
                    </Button>
                    <Button size={'sm'} variant={'white'} onClick={navigateToMessages}>
                        <SvgMessage className={'w-4 h-4 mr-1'}/> Message {activity.name}
                    </Button>
                </>
            // case 'donated':
            //     return <>
            //         <Button size={'sm'} variant={'white'}>
            //             Donate too!
            //         </Button>
            //     </>
            // case 'created_challenge':
            //     return <>
            //         <Button size={'sm'} variant={'white'}>
            //             Share challenge
            //         </Button>
            //     </>
            case 'joined_challenge':
                return <>
                    <Button size={'sm'} variant={'white'}>
                        Share challenge
                    </Button>
                </>
            default:
                return null
        }
    }

    function navigateToNPO(name) {
        props.history.push({pathname: `/non-profit/${name}`, state: {previous: "Back"}})
    }

    function navigateToProfile(username) {
        props.history.push({pathname: `/profile/${username}`, state: {previous: "Back"}})
    }

    function navigateToMessages() {
        props.history.push({pathname: `/messages/`, state: {previous: "Back"}})
    }

    function RenderAttachments({type, cause, navigateToNPO, challenge, group}) {
        const np = cause
        switch (type) {
            case 'donated':
                return (
                    <div
                        className={`${CARD_STYLE} flex flex-row items-center bg-white mt-3 relative z-[2]`} key={np.ein}
                        onClick={() => navigateToNPO(np.name)}>
                        <NPOPicture
                            size={64}
                            np={np}
                            logoUrl={np.logo_url}
                            className={'border border-slate-200'}
                        />
                        <div className='flex flex-col flex-1 ml-12' style={{textAlign: "left"}}>
                            <p className='text-lg text-slate-800 font-semibold'>
                                {np.name}
                            </p>
                            <p className='text-md text-slate-500'>
                                {np.category}
                            </p>
                            <div className='row-ac'>
                                <SvgPin className={'text-blue-500 w-3 h-3'}/>
                                <p className='text-md text-blue-500 ml-4'>
                                    {np.city}, {np.state}
                                </p>
                            </div>
                        </div>
                    </div>
                )
            case ('created_challenge'):
                return (
                    <div className='relative z-[2] mt-3'>
                        <ChallengeCard
                            key={challenge?.challenge_id}
                            challenge={challenge}
                            border
                        />
                    </div>
                )
            case ('joined_challenge' ):
                return (
                    <div className='relative z-[2] mt-3'>
                        <ChallengeCard
                            key={challenge?.challenge_id}
                            challenge={challenge}
                            border
                        />
                    </div>
                )
            case 'created_group':
                return (
                    <div className='relative z-[2] mt-3'>
                        <GroupSearchResultItem group={group} key={group.id} user={user}/>
                    </div>
                )
        }
    }


    const activity = props.activity || {};
    const type = activity.type;
    return (
        <div style={{maxWidth: 700}} className='mx-auto bg-white w-full relative p-5 py-3'>
            <div className='flex flex-row items-center flex-1'>
                <div className='absolute top-0 left-0 right-0 bottom-0 z-[0]'
                     style={{background: 'linear-gradient(-53deg, #4287F560, #4287F500'}}
                />
                <div className='relative'>
                    <ProfilePicture userID={activity.username} size={50} className={'border-2 border-white'}/>
                    <div
                        className='absolute text-blue-500 h-8 w-8 bg-white bottom-0 right-0 rounded-full flex flex-col items-center justify-center'>
                        <RenderIcon type={type}/>
                    </div>
                </div>
                <div className='flex flex-col ml-2 flex-1 relative z-1'>
                    <p className='text-lg font-semibold'>
                        {activity.name}
                    </p>
                    <p className='text-blue-500 font-semibold line-clamp-1'>
                        <RenderMessage type={type} cause={activity.non_profit}/>
                    </p>
                </div>
                <div className='flex flex-col gap-2 relative z-1'>
                    <RenderButtons
                        type={type}
                        activity={activity}
                        cause={activity?.non_profit}
                        challenge={activity?.challenge}
                    />
                </div>
            </div>
            <RenderAttachments
                type={type}
                cause={activity?.non_profit}
                challenge={activity?.challenge}
                navigateToNPO={navigateToNPO}
                group={activity?.group}
            />
        </div>
    )
}

export default withRouter(EventInFeedWidget)
