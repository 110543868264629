import React, { Component } from "react";
import ChallengeDonationBar from "../ChallengeDonationBar";
import ChallengeAwarenessBar from "../ChallengeAwarenessBar";
import SvgPiggy from "../icons/SvgPiggy";
import { formatStatistics } from "../../utils/helpers";
import SvgMegaphone from "../icons/SvgMegaphone";
import moment from "moment/moment";
import ChallengeEndDate from "../ChallengeEndDate";
import SvgArrowForward from "../icons/ArrowForward";
import NPOPicture from "../atoms/NPOPicture";
import ChallengePledgeBar from "../ChallengePledgeBar";
import DonateModal from "../DonateModal";
import { withRouter } from "react-router-dom";
import {
  createChallengeAction,
  getChallenge,
  getChallengeActions,
  getPrivateChallenge,
  getPrivateChallengeName,
  getChallengeByName
} from "../../api/challenges";
import AuthModal from "../AuthModal";
import { wait } from "../../utils/timestamp";
import { createDonationCheckout } from "../../api/stripe_connect";
import SvgFire from "../icons/SvgFire";
import { Button } from "../atoms/Button";
import SvgMoney from "../icons/SvgMoney";
import { connect } from "react-redux";
import SponsoredPostBanner from "../atoms/SponsoredPostBanner";
import SvgTarget from "../icons/SvgTarget";
import ViewMoreBanner from "../atoms/ViewMore";
import { getNonProfitMentions } from "../../api/feeds";
import { challengePostViewed, sponsoredChallengeClicked } from "../../api/challenges";

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    authUser: state.userReducer.authUser,
    mobile: state.userReducer.mobile,
    interests: state.userReducer.interests,
  };
}

class PostChallengeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewArray: [],
      mobileMenu: false,
      featured_artists: [],
      featured_venues: [],
      mobile: false,
      challenge: {},
      nonProfit: {},
      actions: [],
      authModalOpen: false,
      donateModalOpen: false,
      copied: false,
      activeTab: "Challenge Activity",
      amount: 0,
      loading: true,
      memo: "",
      authedUser: false,
      hasBeenViewed: false,
    };
    this.contentRef = React.createRef();
  }

  navigateToChallenge = (name, np) => {
    if (this.props.noRedirect) return;
    this.props.history.push({
      pathname: `/challenge/${np}/${name}`,
      state: { previous: "Back" },
    });
  };

  navigateToNPO = (name) => {
    if (this.props.noRedirect) return;
    this.props.history.push({
      pathname: `/non-profit/${name}`,
      state: { previous: "Back" },
    });
  };

  postClicked = () => {
    sponsoredChallengeClicked(
      this.props.authUser,
      this.props.challenge.challenge_id
    );
  };

  updateAmount = (amount) => {
    this.setState({ amount: amount });
  };

  onChangeMemo = (memo) => {
    this.setState({ memo });
  };

  handleAddView = () => {
    this.props.addToViewedArray(this.props.challenge.challenge_id);
  };

  componentDidMount = () => {
    this.getInfo();
    this.checkContentHeight();
    this.observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting && !this.state.hasBeenViewed) {
          this.handleAddView();
          challengePostViewed(
            this.props.authUser,
            this.props.challenge.challenge_id
          );
          this.setState({ hasBeenViewed: true });
          this.observer.unobserve(this.contentRef.current);
        }
      },
      { threshold: 0.5 }
    );

    if (this.contentRef.current) {
      this.observer.observe(this.contentRef.current);
    }
  };

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  checkContentHeight = () => {
    const contentWrapper = this.contentRef.current.querySelector(
      ".relative.w-full.overflow-hidden"
    );
    if (contentWrapper && contentWrapper.scrollHeight > 188) {
      this.setState({ shouldShowSeeMore: true });
    }
  };

  getInfo = async () => {
    const parts = window.location.pathname.split("/");
    const numArgs = parts.length - 2;
    let challenge, actions, challenge_name, np_name;

    if (numArgs === 1 && !!this.props.authUser?.uid) {
      challenge_name = parts[parts.length - 1].split("?")[0];
      challenge = await getPrivateChallenge(
        this.props.authUser,
        challenge_name
      );
    } else if (numArgs === 2) {
      challenge_name = parts[3].split("?")[0];
      np_name = parts[2].split("?")[0];
      challenge = await getPrivateChallengeName(
        this.props.authUser,
        challenge_name,
        np_name
      );
    } else {
      console.error("Invalid URL structure");
      return;
    }
    const challengeId = challenge.challenge.challenge_id;

    actions = await getChallengeActions(challengeId);
    const posts = await getNonProfitMentions(
      null,
      challenge.challenge.non_profit.ein
    );

    this.setState({
      challenge: challenge.challenge,
      nonProfit: challenge.challenge.non_profit,
      actions: actions.actions,
      loading: false,
      posts: posts.mentions,
      challengeId,
    });
  };

  pledgeChallenge = async () => {
    this.setState({ loading: true });
    const challenge = this.state.challenge;
    const params = {
      type: "pledge",
      amount: 1,
      non_profit_id: challenge.non_profit_id,
      challenge_id: challenge.challenge_id,
    };
    await createChallengeAction(this.props.authUser, params);
    this.getInfo();
  };

  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  followNp = async () => {
    this.setState({ loading: true });
    const { challenge, nonProfit } = this.state;
    const params = {
      type: "awareness",
      amount: 1,
      non_profit_id: challenge.non_profit_id,
      challenge_id: challenge.challenge_id,
    };
    await this.props.addInterest({ id: nonProfit.ein, name: nonProfit.name });
    await createChallengeAction(this.props.authUser, params);
    this.getInfo();
  };

  createCheckout = async () => {
    createDonationCheckout(
      this.props.authUser,
      this.state.amount,
      this.props.challenge.non_profit_id,
      false,
      this.props.challenge.challenge_id,
      this.state.memo
    );
  };

  openAuthModal = () => {
    if (!!this.props.user) {
      this.createCheckout();
    } else {
      this.setState({ authModalOpen: true });
    }
  };

  closeAuthModal = () => {
    this.setState({ authModalOpen: false });
  };

  openDonateModal = () => {
    if (!!this.props.user) {
      this.setState({ donateModalOpen: true });
    } else {
      this.setState({ authModalOpen: true });
    }
  };

  closeDonateModal = () => {
    this.setState({ donateModalOpen: false });
  };

  updateAmount = (amount) => {
    this.setState({ amount: amount });
  };

  onChangeMemo = (memo) => {
    this.setState({ memo });
  };

  copyLink = () => {
    navigator.clipboard.writeText(
      `https://share.starfishapp.com/challenge/${this.state.challengeId}`
    );
    this.setState({ copied: true });
    wait(2000).then(() => this.setState({ copied: false }));
  };

  render() {
    const challenge = this.props.challenge || {};
    const matching = challenge.donation_match || 0;
    const nonProfit = challenge.non_profit || {};
    const challengeEndDate = moment(challenge.end_date);
    const currentDate = moment();
    const inPast = challengeEndDate.isBefore(currentDate);
    const expired = new Date() > new Date(challenge.end_date);
    return (
      <div
        className={`bg-white cursor-pointer br-12 p-4 mb-2 ${this.props.className} mx-auto w-full`}
        style={{
          maxWidth: this.props.inFeed && 700,
          padding: this.props.hasBorder && 0,
        }}
        ref={this.contentRef}
      >
        <SponsoredPostBanner post={challenge} />
        <div className="relative">
          <img
            src={challenge.cover_image}
            style={{
              width: "100%",
              height: this.props.mobile ? 120 : 280,
              objectFit: "cover",
            }}
            className="rounded-2xl"
          />
          <div className="flex flex-col items-start absolute bottom-4 right-4 text-white/50 hover:text-white">
            <div
              className="rounded-2xl flex flex-row items-center backdrop-blur-sm gap-3 border bg-slate-800/20 hover:bg-slate-700/20 border-slate-800/10 p-2 hover:border-slate-600/50 cursor-pointer"
              onClick={(e) => {
                this.navigateToNPO(nonProfit.name);
                e.stopPropagation();
              }}
            >
              <NPOPicture size={40} np={nonProfit} className={"bg-white"} />
              <div className="col-plain">
                <p className="text-sm font-medium text-white/80">
                  A challenge for
                </p>
                <p className="text-md font-semibold text-white -mt-1">
                  {nonProfit.name}
                </p>
              </div>
              <SvgArrowForward className={"ml-12"} />
            </div>
          </div>
        </div>
        <div className="row-jb mt-8">
          <div>
            <p className="text-2xl font-semibold mt-12">
              {challenge.challenge_name}
            </p>
            <div className="row-ac text-slate-700 text-md font-medium mb-8 mt-4">
              <p>Created by</p>
              <img
                src={`https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/users%2Fprofile_picture%2F${challenge.creator}.jpeg?alt=media`}
                style={{ width: 20, height: 20 }}
                className="br-32 mh-4"
              />
              <p>{challenge.creator_name}</p>
            </div>
          </div>
          <ChallengeEndDate challenge={challenge} />
        </div>
        <>
          <ViewMoreBanner
            post={challenge}
            onClick={() => {
              this.postClicked();
              this.navigateToChallenge(
                challenge.challenge_name,
                challenge.creator
              );
            }}
          />
        </>
        {inPast ? (
          <div className="mb-12 p-4 br-12 bg-blue-500/10">
            <h2 className="text-blue-500 text-lg font-medium m-0">
              Challenge Ended!
            </h2>
            {!!challenge.donations_progress && (
              <div className="row-ac pt-12 text-blue-500">
                <SvgPiggy style={{ width: 20, height: 20 }} />
                <p className="ml-8 text-md font-medium">
                  {`$${formatStatistics(challenge.donations_progress, 1)}`}{" "}
                  raised
                </p>
              </div>
            )}
            {!!challenge.awareness_progress && (
              <div className="row-ac pt-12 text-blue-500">
                <SvgMegaphone style={{ width: 20, height: 20 }} />
                <p className="ml-8 text-md font-medium">
                  This cause gained {challenge.awareness_progress} new followers
                </p>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-row gap-x-2 w-full items-center">
            <div className="flex flex-col gap-3 w-full">
              {challenge.donations_goal > 0 ? (
                <div className="mb-12">
                  <ChallengeDonationBar
                    challenge={challenge}
                    matching={matching}
                  />
                </div>
              ) : null}
              {challenge.awareness_goal > 0 ? (
                <div>
                  <ChallengeAwarenessBar
                    challenge={challenge}
                    matching={matching}
                  />
                </div>
              ) : null}
              {challenge.pledge_goal > 0 ? (
                <div>
                  <ChallengePledgeBar challenge={challenge} />
                </div>
              ) : null}
            </div>
            <div className="justify-end">
              {challenge.donations_goal > 0 && !expired ? (
                <Button
                  onClick={this.openDonateModal}
                  variant={"default"}
                  radius={"full"}
                  className="flex-1"
                  style={{ backgroundColor: matching > 0 ? "#F8969C" : null }}
                >
                  {matching > 0 ? (
                    <SvgFire
                      style={{ width: 20, height: 20, marginBottom: 1 }}
                      className={"mr-4"}
                    />
                  ) : (
                    <SvgMoney
                      style={{ width: 20, height: 20, marginBottom: 1 }}
                      className={"mr-4"}
                    />
                  )}
                  Donate {matching > 0 ? " (2x)" : null}
                </Button>
              ) : null}
              {challenge.pledge_goal > 0 &&
              !expired &&
              !challenge.your_contributions?.direct_pledge ? (
                <Button
                  onClick={this.pledgeChallenge}
                  variant={"default"}
                  radius={"full"}
                  className="flex-1"
                >
                  <SvgTarget
                    style={{ width: 20, height: 20, marginBottom: 1 }}
                    className={"mr-4"}
                  />
                  I pledge
                </Button>
              ) : null}
              {challenge.awareness_goal > 0 &&
              !expired &&
              !this.props.interests.some(
                (interest) => interest.id === nonProfit.ein
              ) ? (
                <Button
                  onClick={this.followNp}
                  variant={"default"}
                  radius={"full"}
                  className="flex-1"
                >
                  <SvgTarget
                    style={{ width: 20, height: 20, marginBottom: 1 }}
                    className={"mr-4"}
                  />
                  Follow
                </Button>
              ) : null}
            </div>
          </div>
        )}
        <AuthModal
          isOpen={this.state.authModalOpen}
          closeModal={this.closeAuthModal}
          mobile={this.props.mobile}
          createCheckout={this.createCheckout}
        />
        <DonateModal
          isOpen={this.state.donateModalOpen}
          closeModal={this.closeDonateModal}
          openAuthModal={this.openAuthModal}
          mobile={this.props.mobile}
          challenge={challenge}
          nonProfit={nonProfit}
          updateAmount={this.updateAmount}
          memo={this.state.memo}
          onChangeMemo={this.onChangeMemo}
        />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(PostChallengeComponent));
